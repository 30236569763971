// Resource Page - Card Styles
.resource-card {
    border: 0px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
      img {
        height: 225px;
        width: 100%;
        object-fit: cover;
      }

      .resource-card-background-image {
        display: block;
        height: auto;
        width: 100%;
        background-position: center;
        background-size: cover !important;
        background-repeat: no-repeat;
        &:after {
          content: '';
          display: block;
          padding-bottom: 50%;
          height: 100%;
          width: 100%;
          
        }
      }
      .entry-tag {
        padding: 2rem 2rem 0 2rem;
        .tag {
          display: inline-block;
          color: map-get($theme-colors, secondary);
          padding: .5rem 1rem;
          margin: .25rem;
          background-color: $white;
          text-transform: uppercase;
          font-weight: bold;
          box-shadow: 0 0 4px 0 rgba(8, 77, 109, 0.16);
        }
      }
    }
    &__body {
      margin: 0 2rem;
      .post-title {
        margin-bottom: 2rem;
      }
      .post-details {
        color: map-get($theme-colors, primary);
        .title {
          font-weight: normal;
        }
      }
    }
    &__footer {
      margin: 0 2rem;
    }
}

// What's New
.what-is-new-wrapper {
  // display: flex;
  // flex-wrap: wrap;
  // margin-bottom: 3rem;
}
.what-is-new {
  width: 100%;
  position: relative;
  padding: 3rem 1.5rem;

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  &:nth-child(2n) {
    &:before {
        display: none;
    }
  }

  &:before {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    content: '';
    width: 2px;
    height: calc(100% - 2rem);
    margin: 1rem 0;
    background-image: url('../images/straight-line.svg');
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
        display: block;
    }
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    width: calc(100% - 2rem);
    height: 2px;
    margin: 0 1rem;
    background-image: url('../images/menu-line-horizontal.svg');
    background-repeat: no-repeat;
  }
}

// Related Posts
.related-post--article {
  header {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.tool-pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .pages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;

    .pages-item {
      margin: 0 .5rem;
      .page-number {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        font-weight: bold;
        font-size: 1.25rem;
        color: map-get($theme-colors, secondary);

        &.current {
          color: $white;
          background-color: map-get($theme-colors, secondary);
          border-radius: 50%;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    .pages-link {

    }
  }

  a {
    font-size: 0;
    color: map-get($theme-colors, secondary);

    i {
      font-size: 1.25rem;
    }
  }
}


.what-is-new-wrapper {

    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 20px;
    margin-bottom: 10px;
  &.active {
    .item-wrapper {
      max-height: 100%;
    }

    &>h4 {
      &:after {
        transform: rotate(180deg);
        transition: transform .15s linear;
      }
    }
  }

  &>h4 { 
    cursor: pointer;
    position: relative;

    &:after {
      content: "\f078";
      color: #5bc4bf;
      position: absolute;
      font-family: Font Awesome\ 5 Pro;
      font-size: 37px;
      right: 0;
      top: 0;
      transform: rotate(0deg);
      transition: transform .15s linear;

    }


  }

  .item-wrapper {
    display: flex;
    flex-direction: row;  
    flex-wrap: wrap;
    max-height: 0;
    overflow:hidden;

  }

  .block-highlight {
    width: 100%;
    padding: 10px;
    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    &:nth-child(odd) {
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
    }
    
    .highlight-wrapper {
      border: 1px solid map-get($theme-colors, primary);
      
    }


    .title {
        background-color: map-get($theme-colors, primary);
        color: #fff;
        padding: 10px;
        position: relative;
        font-size: 18px;
        font-weight: bold;

        
    }

    .content {
      padding: 10px;
    }
  }

}

// Testimonials

.client-testimonials-single {
  iframe {
    display: block;
    margin-bottom: 3rem;
    width: 100%;
  }
}


// Share Container - Share Story
.share-container {
  .share-btn {
    margin-bottom: 2rem;
    a.share-button {
      margin: 0 1rem;
      font-size: 1.5rem;
      cursor: pointer;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
// Share Container - Share Story -- END