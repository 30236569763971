* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  color: map-get($theme-colors, primary);
  
  p {
    color: map-get($theme-colors, primary);
  }
}

#main {
  padding: 0;
}

.cover-background {
  @include position(absolute, 0, 0, 0, 0);
  z-index: -2;

  &.overlay::after {
    content: '';
    @include position(absolute, 0, 0, 0, 0);
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.entry-content {
  img {
    max-width: 100%;
  }
}

/**
 * Responsive Video Embeds 
 */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/**
 * Remove Mobile IOS Styles
 */
input, textarea, button {
  -webkit-appearance: none;
  -webkit-border-radius:0px;
}


// Global Links within Body Copy
p {
  a { 
    &:not(.btn) {
      text-decoration: underline !important;
    }
    &:hover {
      opacity: .6;
    }
  }
}


