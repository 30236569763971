.offset-grid {
	padding: 1.5rem 0;

	.icon-block {
		padding-bottom: 4rem;

		@include media-breakpoint-up(md) {
			padding-bottom: 0;
		}
	}

	&.large-icons {
		.icon-grid-item {
			.icon-grid {
				&--icon {
					width: 150px;
					max-width: 150px;
				}
			}
		}
	}
	
	.icon-grid {

		&--lede {
			color: map-get($theme-colors, primary);	
			font-size: 16px;	
			font-weight: bold;	
			line-height: 24px;
		
		}
		
		&--title {
			color: map-get($theme-colors, secondary);	
			font-size: 33px;	
			font-weight: bold;	
			line-height: 48px;
		}
		
		&--content {

		}

		&--icon {
			width: 170px;
		}

		&--link {
			font-weight: bold;
			color: map-get($theme-colors, secondary);
			transition: all .25s ease-in-out;

			.icon {
				margin: 0 .5rem;
			}

			&:hover {
				text-decoration: none;
				color: map-get($theme-colors, primary);
			}
		}

	}
	
	.icon-grid-item {
		margin-bottom: 1rem; 
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		&.vertical {
			flex-direction: column;

			.icon-grid {

				&--heading {
					color: map-get($theme-colors, primary);	
					font-size: 1rem;	
					line-height: 24px;
					margin-bottom: 1rem;
				}

				&--icon {
					position: relative;
					padding-bottom: 1rem;
					margin-bottom: 1rem;

					@include media-breakpoint-up(lg) {
						padding-bottom: 22.5px;
						margin-bottom: 0px;
					}

					&:after {
						content: "";
						display: block;
						width: 84px;
						height: 1px;
						border: 1px solid map-get($theme-colors, secondary);
						position: absolute;
						bottom: 0;

						@include media-breakpoint-up(lg) {
							width: 55px;
						}
					}

				}
			}
		}
	}
}