/*
 * This is the stylesheet used to style social media elements.
 * @see blocks/social-media.twig
 * The idea is you wrap the social partial in a wrapper and use that to style individual sections.
 *
 * .social-media -> ul
 * &--item -> li
 * &--link -> a
 */

 .social-dark .social-media__link {
   color: map-get($theme-colors, secondary);

   @include active-state() {
     color: darken(map-get($theme-colors, primary), 15%);
   }
 }

 .social-media {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.primary {
    .social-media__link {
      color: map-get($theme-colors, primary);
    }
  }

  &__wrapper {
    padding: 5px 0;
  }

  &__item {
    margin: 0 7.5px;
  }

  &__link {
    @include font-size(14);
    @include active-state() {
      color: map-get($theme-colors, primary);
    }
    transition: color 0.15s linear;
  }
}

.primary-social-media {
  .social-media__item:last-of-type {
    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }
}

// EXAMPLES

//.header-social--wrapper {
//  .social-media {
//    &--item {}
//    &--link {}
//  }
//}

//.footer-social--wrapper {
//  .social-media {
//    &--item {}
//    &--link {}
//  }
//}
