.filter-bar {
    .cat-filter label {
        display: none !important;
    }
    .category-fancy-select {
        width: 100% !important;
        border: 1px solid rgba($black, 0.1);

        > .dropdown-toggle {
            background-color: $white;
        }

        button {
            .filter-option {
                &-inner {
                    &-inner {
                        color: rgba(8,77,109,0.64);
                    }
                }
            }
            &:after {
                font-family: "Font Awesome 5 Pro";
                display: block;
                content: "\f078";
                border: 0;
                width: auto;
                height: auto;
                color: map-get($theme-colors, primary);
            }
        }
    }
}

.search-btn-container {}
.search-btn {}