.block {
}

.hero-slider {
	.slide {
		position: relative;
		padding: 70px 15px;

		&-inner {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-up(lg) {
				min-height: 450px;
			}
		}

		.cover-background {
			@include position(absolute, 0, 0, 0, 0);
			z-index: -2;

			&::after {
				content: "";
				@include position(absolute, 0, 0, 0, 0);
				background: rgba(0, 0, 0, 0.7);
				z-index: -1;
			}
		}
	}
}


// Contact Page Styles
.contact-container {
	padding: 24px;
	background-color: #fff;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
}

body.contact {

	.contact-details {

		.location {
			width: 80%;
		}

		&__item {
			display: flex;
			flex-direction: row;
			@include font-size(18);
			margin: .75rem 0;

			.icon {
				max-width: 30px;
				min-width: 30px;
				text-align: center;
				margin-right: .75rem;
				color: map-get($theme-colors, secondary);
			}
		}
	}

}

.featherlight-popup {
	display: none;
}

.featherlight-content {
	min-width: 50%;
}
.featherlight .featherlight-inner {
	padding: 70px;
}

.featherlight .featherlight-close-icon {
	width: 75px;
	height: 75px;
	@include font-size(20);
}

body.with-feather {
	.featherlight-popup {
		display: block;
	}
}
// Contact Page Styles -- end 

// product-template-default
body.product-template-default {
	.reduced-header {
		margin-bottom: 0 !important;
	}
}

.related-title {
	color: map-get($theme-colors, secondary);
	@include media-breakpoint-up(lg) {
		margin-bottom: 68px;
	}

	&.story {
		margin: 0;
		@include media-breakpoint-up(lg) {
			margin-top: 35px;
			margin-bottom: 25px;
		}
	}
}

.related-posts-sidebar {
	margin-bottom: 67px;
	.entry-title {
		a {
			font-size: 16px;	
			font-weight: bold;	
			line-height: 24px;
			margin-bottom: 22.41px;
		}
	}
}

.singular-breadcrumb {
	margin-bottom: 50px;
	a {
		color: #5BC4BF;	
		font-size: 14px;	
		font-weight: bold;	
		line-height: 22px;

		&:hover {
			color: darken(#5BC4BF, 15);
			cursor: pointer;
			text-decoration: none;
		}
	}
		
	i {
		margin-right: 14.5px;
	}
}

.single-post-article {
	@include media-breakpoint-up(lg) {
		margin-bottom: 60px;
	}
}

.share-icons {
	display: flex;
	flex-direction: row;
	list-style: none;
	padding-left: 0;
	margin-bottom: 40px;
	li {
		i {
			font-size: 22px;
			margin-right: 10px;
			color: map-get($theme-colors, secondary);
		}
	}
}

.signup {
	padding: 21px 21px 30px 21px;
	background-color: map-get($theme-colors, secondary);
	color: #fff;
	border-radius: 4px;

	.signup-title {
		font-size: 25px;	
		font-weight: bold;	
		line-height: 34px;
		margin-bottom: 14px;
	}
}

.related-posts--wrapper {
	margin-top: 50px;
	margin-bottom: 0px;

	@include media-breakpoint-up(lg) {
		margin-bottom: 150px;

	}
	.related-post-title {
		color: map-get($theme-colors, secondary);
		font-size: 33px;	
		font-weight: bold;	
		line-height: 48px;
		margin-bottom: 60px;
	}

	.entry-title {
		font-size: 16px;	
		font-weight: bold;	
		line-height: 24px;
	}
}

.share-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@include media-breakpoint-up(lg) {
		flex-direction: column;
	}
}

.search-title {
	font-size: 25px;	
	font-weight: bold;	
	line-height: 34px;
	color: map-get($theme-colors, secondary);
	margin-bottom: 20px;
}

.cat-filter {
	
	label {
		display: block;
		font-size: 14px;	
		line-height: 22px; 
	}

	select {
		border: 1px solid rgba(0,0,0,0.1);	
		border-radius: 2px;
		background-color: transparent;
		width: 100%; 
		padding: 10px 15px;
		margin-bottom: 20px;
		-webkit-appearance: none;
	}
}

.button-container {
	padding-bottom: 20px;
}

.border-bottom-style {
	& > .container {
		border-bottom: 1px solid #E3EBED;
	}
}

.faq--wrapper {
	margin-bottom: 80px;

	&:after {
		display: block;
		content: "";
		width: 100%;
		height: 2px;
		background-image: url(../images/menu-line-horizontal.svg);
		background-repeat: repeat-x;

	}

	.category-badge {
		margin-bottom: 30px;
	}

	.entry-title {
		font-size: 18px;	
		line-height: 28px;
		color: map-get($theme-colors, secondary);
		text-decoration: none;
		font-weight: normal;
		position: relative;
		margin-bottom: 44px;
		cursor: pointer;
		margin-right: 4rem;

		&:after {
			content: "\f078";
			color: map-get($theme-colors, secondary);
			position: absolute;
			font-family: "Font Awesome 5 Pro";
			font-size: 30px;
			right: 0;
			top: 0;
			transform: rotate(0deg);
			transition: transform linear 150ms;
			margin-right: -4rem;
		}

		&.active {
			&:after {
				transform: rotate(180deg);
				transition: transform linear 150ms;
			}
		}

	}

	.entry-summary {
		height: 0;
		overflow:hidden;
		font-size: 18px;
		margin-bottom: 1rem;

		

		&.active {
			height: auto;
		}
	}
}

.teams {
	.team-name {
		font-size: 33px;	
		font-weight: bold;	
		line-height: 48px;	
		text-align: center;  
		margin-bottom: 55px; 
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.team-member {
		position: relative;

		&.active {
			.hidden-content {
				transform: translateX(0%);
				transition: transform linear 150ms;
			}
		}

		&--image {
			overflow: hidden;
		}

		.hidden-content {
			background-color: map-get($theme-colors, secondary);
			color: #fff;
			position: absolute;
			width: calc(100% - 31px);
			padding: 17px 26px;
			bottom: 50px;
			transform: translateX(-100%);
			transition: transform linear 150ms;

		 

			.byline {
				font-size: 16px;	font-weight: bold;	line-height: 24px;
			}

			.label {
				font-size: 25px;	font-weight: bold;	line-height: 34px;
			}
		}

		&--content {
			margin-bottom: 84px;

			.name {
				margin-top: 31px;
				font-size: 25px;	
				font-weight: bold;	
				line-height: 34px;	
				text-align: center;
			}
			.role {
				font-size: 18px;	
				line-height: 28px;	
				text-align: center;
				margin-bottom: 33px;
			}
			.blurb {

			}
		}
	}
}

.career-team-container {
	> .container {
		margin-bottom: 60px;
	}
}


.post-type-archive-whats_new {
	.facetwp-facet {
		margin-bottom: 0px;
		margin-right: 10px;
		width:100%;
		display: flex;
		flex-direction: row;

		select {
			width: 100%;
			height: 100%;
			-webkit-appearance: none;
			padding: 15px 10px;
			background-color: #fff;
		}
	}

}

// Single Service Page Styles
.single-service {
	.page-header {
		margin-bottom: 0;
	}

	.nav-product {
		margin-bottom: 3rem;
	}
}

// Error 404 Page Styles
body.error404 {
	.page-header {
		margin-bottom: 0;
		// 100vh - navigation height - footer;
		min-height: calc(100vh - 189px - 399px);
	}

	.page-introduction {
		display: none;
	}

	.footer {
		margin-top: 0;
	}
}