$headings: $h1-font-size, $h2-font-size, $h3-font-size, $h4-font-size,
$h5-font-size, $h6-font-size;

@for $i from 1 through length($headings) {
  h#{$i},
  .h#{$i} {
    @include responsive-font-size(nth($headings, $i));
    margin-top: $spacer;
  }
}

.headings-white {
  @for $i from 1 through length($headings) {
    h#{$i},
    .h#{$i} {
      color: white;
    }
  }
}

p {
  margin-bottom: $spacer;
}

.paragraphs-white p { 
  color: #fff;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.font-size-lg {
  font-size: $font-size-lg;
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v19-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v19-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v19-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto-v19-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
       url('../fonts/roboto-v19-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v19-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v19-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v19-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v19-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v19-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto-v19-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
       url('../fonts/roboto-v19-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v19-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v19-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v19-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v19-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v19-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v19-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v19-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/roboto-v19-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
       url('../fonts/roboto-v19-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v19-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v19-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v19-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v19-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
