.seperator {
    & > .container {
        padding-bottom: 10%;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding-bottom: 7%;
        }
    }

    &.first-sep {
        &.sep-left {
            .seperator-line {
                width: 115%;
                left: unset;
                right: -7%;
                z-index: -1;
            }
            
        }
        
        &.sep-right {
            .seperator-line {
                width: 115%;
                left: -8%;
                right: unset;
                z-index: -1;
            }
        }

    }

    &.last-sep {
        .seperator-line {
            width: 115%;
        }

        &.sep-left {
            .seperator-line {
                left: -8%;
            }
        }
        
        &.sep-right {
            .seperator-line {
                right: -7%;
                left: unset;
            }

        }
    }
    .seperator-line {
        padding-bottom: 13%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        height: 100%;
        width: 115%;
        left: -8%;
        background-position: center;
        max-height: 10%;
        overflow: hidden;
        opacity: 0;
        @include media-breakpoint-up(lg) {
            opacity: 1;
        }
    }
}

.dotted {
    &-line {
        width: 115%;
        max-width: 115%;
        height: 100%;
        left: -8%;
        position: absolute;
        overflow: hidden;
        
        @media (min-width: 1440px) {
            padding: 2.5rem 0;
        }

        @media (min-width: 1600px) {
            padding: 1.5rem 0;
        }

        @media (min-width: 2000px) {
            padding: 0;
        }
    }
    &-left {
        .container {
            position: relative;
        }
        
        .dotted-line {
            direction: ltr;
            opacity: 0;
            @include media-breakpoint-up(lg) {
                opacity: 1;
            }
        }
    }

    &-right {
        .container {
             position: relative;
        }

        .dotted-line {
            direction: rtl;
            opacity: 0;
            @include media-breakpoint-up(lg) {
                opacity: 1;
            }
        }
    }
}