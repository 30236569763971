.cta-block {

    background-color: map-get($theme-colors, secondary);
    @include media-breakpoint-up(lg) {
        margin: 44px 0 0px;
    }

    &.has-image {
        // margin-top: 30px;    
        // margin-bottom: 30px;
            
        @include media-breakpoint-up(lg) {
            // margin-top: 10%;
            // margin-bottom: 15%;
        }

        &.mobile-offset-image {
            margin-top: 5rem;  
            
            @include media-breakpoint-up(lg) {
                margin-top:44px;
            }
        }
    }

    &--component {

        &__video {
            width: 100%;
            
            iframe {
                width: 100%;
            }
        }

        &__image {

            padding-bottom: 70px;

            @include media-breakpoint-up(lg) {
                position: relative;
                top: -58px;
                width: 100%;
                padding-bottom: 0;
            }
        }

        &__content {

            padding: 97px 16px;

            &--lede {
                color: map-get($theme-colors, primary);	
                font-size: 16px;	
                font-weight: bold;	
                line-height: 24px;	
                text-align: center;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }

            &--title {
                color: $white;	
                font-size: 28px;	
                font-weight: bold;	
                line-height: 34px;	
                text-align: center;
                margin-bottom: 2rem;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                    font-size: 33px;	
                    font-weight: bold;	
                    line-height: 48px;
                }
            }

            &--content {

                color: $white;	
                font-size: 16px;	
                font-weight: bold;	
                line-height: 24px;
                text-align: center;

                a.btn {
                    margin: .5rem;

                    @include media-breakpoint-up(md) {
                        margin: 0;
                    }
                }

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }
        }

        &__grid_items {

            &--wrapper {

                margin-top: 45px;

                .icon-grid-item {

                    .icon-grid--vertical {

                        .icon-grid--content {
                            color: $white;
                            
                            p {
                                font-weight: bold;
                                color: $white;
                            }
                        }
                    }

                    &.horizontal {
                        display: flex;
                        // justify-content: space-between;
                        align-items: flex-start;

                        .icon-grid {
                            
                            &--icon {
                                max-width: 39px;
                                display: inline-block;
                            }
                            
                            &--horizontal {
                                padding-left: 27px;
                                display: inline-block;
                            }
                            
                            &--heading {
                                font-size: 1rem;	
                                font-weight: bold;	
                                line-height: 24px;
                                margin-bottom: 15px;
                            }
                            &--content {
                                font-size: 14px;	
                                line-height: 22px;
                                max-width: 371px;
                                
                            }
                        }
                    }
                    
                    &.vertical {
                        padding: 1rem 0;
                        width: 100%;
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 10px;

                        @include media-breakpoint-up(lg) {
                            width: 30%;
                            padding: 0;
                        }

                        .icon-grid {
                            &--heading {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.mobile-offset-image {

        .mobile-offset-image-mode {
            padding-bottom: 50%;
            
            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
            }
        }

        .cta-block--component__image {

            .large-image {
                padding-bottom: 82%;
                @include media-breakpoint-up(lg) {
                    padding-bottom: 0;
                    height: 110%;
                }
            }

            &.cta-block--component__image {
                &--left {
                    position: absolute;
                    width: 90%;
                    height: 100%;
                    top: -30%;
                    left: 5%;

                    @include media-breakpoint-up(lg) {

                        position: absolute;
                        width: 95%;
                        height: 100%;
                        top: -12%;
                        left: 0;

                    }
                }
            }
        }
    }

    .large-image {

        padding-bottom: 82%; 

        @include media-breakpoint-up(md) {
            padding-bottom: 90%;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 126%;
        }
    }
}