.client-testimonials {
    &__tags {
        margin-bottom: 2rem;
        .tag {
            background-color: $white;
            box-shadow: 0 0 4px 0 rgba(8,77,109,0.16);
            color: map-get($theme-colors, secondary);
            padding: .5rem 1rem;
            margin: .5rem;
            display: inline-block;
            font-weight: $font-weight-bold;
        }
    }
    &__content {
        iframe {
            width: 100%;
        }
        .title {
            margin-right: 1rem;
            margin-bottom: 1.5rem;
        }
    }
    &__details {
        margin-top: 2rem;
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }
}
