/* ==========================================================================
  Pseudo Element Mixins
  ========================================================================== */
  @mixin pseudo($pseudo: 'before', $content: '') {
    &::#{$pseudo} {
      content: $content;
      @content;
    }
  }

/* ==========================================================================
  Clearfix mixin for floats.
  ========================================================================== */
@mixin clearfix {
  @include pseudo('after', '') {
    display: table;
    clear: both;
  }
}

/* ==========================================================================
  Aspect ratio mixin for a background image / Box
  ========================================================================== */
  @mixin aspect-ratio($width, $height) {
    position: relative;

    @include pseudo('after', '') {
      display: block;
      padding-top: ($height / $width) * 100%;
      width: 100%;
    }
  }

/* ==========================================================================
  Font size calculation
  ========================================================================== */

@function calc-rem($size, $base-font-size) {
  $remSize: $size / $base-font-size;
  @return #{$remSize}rem;
}

/* ==========================================================================
  Font size calculation mixin
  ========================================================================== */

@mixin font-size($sizeValue) {
  font-size: ($sizeValue) + px;
  font-size: $sizeValue / 16 + rem;
}

/* ==========================================================================
  Browser compatible animation mixin
  ========================================================================== */
@mixin animation ($delay, $duration, $timing, $animation) {
  -wekit-animation-timing-function: $timing;
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-timing-function: $timing;
  -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-timing-function: $timing;
  -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function: $timing;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}

/* ==========================================================================
  Keyframes mixin for animations
  ========================================================================== */

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} { @content; }
  @-moz-keyframes #{$animationName} { @content; }
  @-o-keyframes #{$animationName} { @content; }
  @keyframes #{$animationName} { @content; }
}

/* ==========================================================================
  Keyframes mixin for animations
  ========================================================================== */
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

/* ==========================================================================
  Active Element State Mixin
  ========================================================================== */
@mixin active-state() {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

 /* ==========================================================================
  Define vertical, horizontal, or both position
  @see https://medium.com/@justinbrazeau/10-useful-sass-mixins-for-automation-833cdee9d69b
  ========================================================================== */

@mixin center($position) {
  position: absolute;
  
  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

/* ==========================================================================
  Input element placeholder mixin 
  ========================================================================== */
@mixin placeholder() {
  &::-webkit-input-placeholder {@content;}
  &:-moz-placeholder {@content;}
  &::-moz-placeholder {@content;}
  &:-ms-input-placeholder {@content;}
}

/* ==========================================================================
  Element positioning mixin
  ========================================================================== */
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/* ==========================================================================
  Retrieves deep map keys
  ========================================================================== */
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
      $map: map-get($map, $key);
  }
  @return $map;
}
