.block.page-builder {
  // padding: $page-builder-block-spacing 0;
}

/*
 * Banner Images
 * @see pb-banner-image-block-layout.twig
 */
.page-builder-banner--block {
  .pb--banner {
    display: block;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      width: 100%;
      transition: padding-bottom 0.5s ease;

      @include media-breakpoint-up(md) {
        padding-bottom: 56.25%;
      }
    }
  }

  &.standard {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 60%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }

  &.full-width {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 65%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }
}

/*
 * Slider
 * @see pb-page-slider-block-layout.twig
*/
.pb--slider {
  // Styling for middle arrows
  &.middle-arrows {
    .owl-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      z-index: 10;
      padding: 0 10px;

      .owl-prev,
      .owl-next {
        &::before {
          font-family: FontAwesome;
          color: #fff;
          font-size: 1rem;
          line-height: 1;
        }

        span {
          display: none;
        }
      }

      .owl-prev::before {
        content: "\f060";
      }

      .owl-next::before {
        content: "\f061";
      }
    }
  }

  &.sc-dots-styling {
    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;

      .owl-dot {
        span {
          background: map-get($theme-colors, secondary);
        }

        &.active {
          span {
            background: map-get($theme-colors, primary);
          }
        }
      }
    }
  }
}

.pb--slide {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }
}

/*
 * Featherlight Styles
*/
.with-featherlight {
  .featherlight .featherlight-content {
    border-bottom: none;
    padding: 0;
  }
}

/*

Stat Block Styles

*/
.block.page-builder.page-builder-statistic-block {
  padding: 10px 0;
}
.page-builder-statistic-block {
  // padding: 2rem 0;

  &--stats {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__block {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      margin: 2.5rem 0;
      @include media-breakpoint-up(md) {
        width: 33.33%;
      }
      @include media-breakpoint-up(lg) {
        width: 20%;
      }

      h2, h3 {
        margin: 0;
      }
    }
    &__spacer {
      display: none;
      width: 150px;
      height: 2px;
      margin: auto 1rem;

      &:after {
        display: block;
        content: '';
        width: calc(100% - 2rem);
        height: 2px;
        margin: 0 1rem;
        background-image: url('../images/menu-line-horizontal.svg');
        background-repeat: no-repeat;
      }
      &:last-child {
        display: none;
      }
      &:nth-child(3n) {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}

/*

Request a Demo Header Styles

*/
.request-a-demo-header {
  a.btn {
    margin: 1.5rem 0;
  }
  p {
    color: map-get($theme-colors, primary);
    @include font-size(16);
  }
  &__content {
    margin-right: 10%;
  }
  &__heading {
    color: $white;
  }
  &__spacer {
    display: block;
    width: 70%;
    height: 2px;
    margin: 2rem 0;

    &:after {
      display: block;
      content: '';
      width: calc(100% - 2rem);
      height: 2px;
      background-image: url('../images/request-a-demo-line.svg');
      background-repeat: no-repeat;
    }
  }
  &__contact-form {
    background-color: $white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      padding: 70px 50px;
    }
  }
}

// Page Builder - Text Block Layout
.page-builder--text-block {
  .pb--text-block_wrapper {
    &.contained {
      width: 70%;
      margin: 0 auto;
    }
  }
}

// Page Builder - Image w/ Caption layout
.page-builder--image-w-caption-layout {
  .page-builder--image-w-caption_image--wrapper {
    .background-image {
      padding-bottom: 100%;
    }
  }
}

// Feature Logo Slider
.page-builder--logo-feature-block {
  .feature-logo {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 100%;
  }

  .logo-slider {

    .slick-track {
      display: flex !important;

    }

    .slick-slide {
      height: inherit !important;
    }

    .slick-slide {
      > div {
        height: 100%;
      }
    }

    .slider-controls {
      .slick-arrow {
        padding: .5rem;
        cursor: pointer;
        font-size: 1.25rem;
  
        &:hover {
          opacity: .6;
        }
      }
    }

    .slick-dots {
      z-index: 10;
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 1rem 0;
      padding: 0;
      li {
        margin: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-content: center;
        button {
            font-size: 0;
            height: 10px;
            width: 10px;
            padding: 0;
            border-radius: 5px;
            background-color: $transparent;
            border: 2px solid map-get($theme-colors, primary );
            outline: none;
            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }

        &.slick-active {
            button {
                background-color: map-get($theme-colors, primary );
                border: 2px solid map-get($theme-colors, primary );
            }
        }
      }
    }
  }
  
}