/* ==========================================================================
		Page Header Specific Styles
	 ========================================================================== */
.page-header {
	margin-bottom: 40px;
}

.standard-page-header {
	padding: 80px 0;

	@include media-breakpoint-up(md) {
		min-height: 300px;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
	}
}

.standard-page-header {
	background-color: map-get($theme-colors , secondary );
	&__inner {
		.header-content {
			color: #fff; 
			@include media-breakpoint-up(xl) {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}

			&--title {

				h1 {
					color: #fff;
				}
			}

			&--content {
				font-size: 18px;
			}
		}
		.feature-content {
			padding: 0 1rem;
			&--has-image {
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&--has-slider {
				.slider-container {
					position: relative;
					margin-bottom: -5px;
					.slider-dots {
						position: absolute;
						bottom: calc-rem(30px, 16px);
						right: calc-rem(30px, 16px);
						ul {
							list-style: none;
							display: inline-flex;
							margin: 0;
							padding: 0;
							li {
								margin: 0 .5em;
								&.slick-active {
									button {
										background-color: $white;
									}
								}
								button {
									font-size: 0;
									height: 12px;
									width: 12px;
									padding: 0;
									border: 0;
									border-radius: 6px;
									background-color: rgba($white, .25);
									outline: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

.front-page-header {
	background-color: map-get($theme-colors , secondary );
	// margin: 2rem 0 0rem;
	@include media-breakpoint-up(lg) {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	&__inner {
		margin-bottom: 15rem;
		@include media-breakpoint-up(lg) {
			// flex-direction: row;
			margin-bottom: 0;
		}

		.header-content {
			color: #fff;
			font-size: 20px;	
			line-height: 26px;
			padding-top: 28px;
			padding-bottom: 42px;
			flex: 1 0 auto;
			width: 100%;

			&--inner {
				width: 100%;
				flex: 1 0 auto;
				
			}
			
			@include media-breakpoint-up(lg) {
				// padding: 0 5rem 0 0;
				padding-top: 138px;
				padding-bottom: 142px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}

			h4 {
				font-weight: $font-weight-bold;
				font-size: 2.938rem;
			}
			.typed-wrapper {
				margin-bottom: .75rem;
				min-height: 120px;
			}
			#typed {
				display: inline !important;
			}
			#typed, .typed-cursor {
				font-size:  $h2-font-size;
				font-weight: bold;
				color: map-get($theme-colors, primary);
			}

			.button-wrapper {
				display: flex;
				flex-wrap: wrap;

				.btn {
					margin-right: .75rem;
				}
			}
		}
		.feature-content {
			margin-bottom: -10rem;
			@include media-breakpoint-up(xl) {
				padding: 0;
			}

			&--has-image {
				// margin-bottom: -10rem;
				@include media-breakpoint-up(lg) {
					margin: -3rem 0;
				}
				img {
					display: block;
					width: 100%;
					height: 120%;
					position: absolute;
					top: -10%;
				}

				.meta-image {
					padding-bottom: 100%;

					@include media-breakpoint-up(lg) {
						padding-bottom: 0;
					}
				}
			}
			&--has-video {
				margin-bottom: -10rem;
				@include media-breakpoint-up(xl) {
					margin: -3rem 0;
				}
			}
			&--has-slider {
				margin-bottom: -10rem;
				@include media-breakpoint-up(xl) {
					margin: -3rem 0;
				}
				.front-page-slider {
					position: relative;
					margin-bottom: -5px;
					.slider-dots {
						position: absolute;
						bottom: calc-rem(30px, 16px);
						right: calc-rem(30px, 16px);
						z-index: 5;
						ul {
							list-style: none;
							display: inline-flex;
							margin: 0;
							padding: 0;
							li {
								margin: 0 .5em;
								&.slick-active {
									button {
										background-color: $white;
									}
								}
								button {
									font-size: 0;
									height: 12px;
									width: 12px;
									padding: 0;
									border: 0;
									border-radius: 6px;
									background-color: rgba($white, .25);
									outline: none;
								}
							}
						}
					}

					.slide {
						.slide-image {
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center;
							&:after {
								content: '';
								padding-bottom: 150%;
								display: block;
							}
						}
					}
				}
			}
		}
	}
}

.post-page-header {
	background-color: map-get($theme-colors, secondary);
	color: #fff;
	padding: 70px 0;

	&__inner {
		.post-category {
			margin: 2rem 0;
			.category {
				padding: .5rem 1rem;
				margin: .25rem;
				display: inline-block;
				background-color: rgba(255,255,255,0.2);
				box-shadow: 0 0 4px 0 rgba(8,77,109,0.16);
				text-transform: uppercase;
				font-weight: bold;
			}
		}
		.post-details {
			h6 {
				font-weight: normal;
			}
			.spacer {
				margin: 0 1rem;
			}
			.read-time {
				color: $white;
			}
			.posted {
				color: map-get($theme-colors, primary);
			}
		}

		.background-image {
			background-position: center !important;
			background-size: contain;
			background-repeat: no-repeat;
			display: block;

			&:after {
				content: '';
				display: block;
				padding-bottom: 70%;
			}
		}
	}

	.header-content {
		color: #FFFEFE;		
		font-size: 47px;	
		font-weight: bold;	
		line-height: 60px;
	}
}

//Featured Post Page Header
.featured-post-page-header {
	background-color: map-get($theme-colors , secondary );
	margin: 2rem 0 6rem;
	@include media-breakpoint-up(lg) {
		margin: 3rem 0 10rem;
	}

	&__inner {
		margin-bottom: 15rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		.header-content {
			color: $white;
			font-size: 20px;	
			line-height: 26px;
			padding-top: 28px;
			padding-bottom: 42px;

			.category-wrapper {
				.category {
					background-color: rgba(255,255,255,0.2);
					box-shadow: 0 0 4px 0 rgba(8,77,109,0.16);
					font-weight: $font-weight-bold;
					font-size: .75rem;
					padding: .5rem 1rem;
				}
			}

			h1 {
				color: $white;
			}

			.post-details {
				font-size: 1rem;
				margin: 2rem 0;
				.read-time {
					.icon {
						margin-right: .5rem;
					}
				}
				.posted-date {
					color: map-get($theme-colors, primary);
				}
			}
			
			@include media-breakpoint-up(lg) {
				padding-top: 138px;
				padding-bottom: 142px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}
		}
		.feature-content {
			margin-bottom: -10rem;
			@include media-breakpoint-up(xl) {
				padding: 0;
			}

			&--has-image {
				@include media-breakpoint-up(md) {
					margin: -3rem 0;
				}
			}

			&__background-image {
				height: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}
