/* ==========================================================================
   Header
   ========================================================================== */

body.home {
  header.site-header {
    top: 0;
    position: fixed;
    width: 100%;
    background-color: $white;

    &.shadow {
      box-shadow: 0 2px 14px 0 rgba(8,77,109,0.17);
    }
  }

  .front-page-header {
    margin-top: 120px;
    @include media-breakpoint-up(lg) {
      margin-top: 240px;
    }
  }
}

.brand {
  &__logo {
    display: inline-block;
    width: 100px;
    @include media-breakpoint-up(lg) {
      width: 120px;
    }
  }
}

.primary-navigation__wrap {
  @include media-breakpoint-up(lg) {
    padding: 2rem 0;
  }

  .brand-logo {
    img {
      width: 125px;
      height: auto;
    }
  }
}

.nav--sub-primary ul {
  margin-bottom: 0;
}


.site-header {
  position: relative;
  z-index: 10;
  &__top {
    display: none;
    margin: 0 10% 0 10%;
    .container {
      padding: 1rem 0;
      border-bottom: .5px solid rgba(map-get($theme-colors, "primary"), 0.33);
    }
    @include media-breakpoint-up(lg) {
      display: block;
    }

    .primary-social-media {
      border-left: 1px solid rgba(map-get($theme-colors, "primary"), 0.33);
      margin-left: 1rem;
      padding-left: 1rem;
    }
  }
}

.site-menu__trigger {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: -1.5rem;

  @include media-breakpoint-up(lg) {
    margin: initial;
  }

  &.dark {
    color: $gray;
  }
  
  i.fa {
    color: $white;
    padding: calc-rem(56px, 16px) 1rem;
    background-color: map-get($theme-colors, "primary");
  }

  span {
    display: none;

  }

}

.reduced-header {
  &.reduced-page-header {
    background-color: #E3EBED;
    padding-top: 82px;
    padding-bottom: 75px;

    &.override_style {
      background-color: map-get($theme-colors, 'secondary');
      color: $white;
    }
  }

  &--content {
    padding-top: 2rem;
    padding-bottom: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    font-size: 	0.875rem;
    
    .inline-svg,
    svg {
      max-width: 40px;
    }
    h1 {
      font-size: 33px;
      width: 100%;
    }

    h2, .h2 {
      color: inherit;
    }

    p, .p {
      color: $white;
      @include media-breakpoint-up(lg) {
        @include font-size(20);
      }
    }
  }

  &--breadcrumb {
    color: map-get($theme-colors, secondary);	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 22px;
    position: absolute;
    top: 0;
    left: 0;

    a {
      color: map-get($theme-colors, secondary);	
      &:hover {
        text-decoration: none;
        
      }
    }
  }

  &--feature {
    &__image {
      width: 100%;
      height: 100%;
      .background-image {
        background-size: contain !important;
        &:after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
      }
    }
    &__video {}
    &__embed-video {
      width: 100%;
      iframe {
        width: 100%;
      }
    }
  }
}
