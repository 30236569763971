// @font-face {
//     font-family: star;
//     src: url(../../../../../plugins/woocommerce/assets/fonts/star.eot);
//     src: url(../../../../../plugins/woocommerce/assets/fonts/star.eot?#iefix) format("embedded-opentype"), url(../../../../../plugins/woocommerce/assets/fonts/star.woff) format("woff"), url(../../../../../plugins/woocommerce/assets/fonts/star.ttf) format("truetype"), url(../../../../../plugins/woocommerce/assets/fonts/star.svg#star) format("svg");
//     font-weight: 400;
//     font-style: normal;
//   }
  
  
  .price ins {
    font-weight: 400;
  }
  
  .woocommerce-active {
    .site-header .col-full {
      position: relative;
    }
    .site-branding {
      float: left;
    }
  }
  
  .site-header-cart {
    list-style-type: none;
    margin-left: 0;
    padding: 0;
    margin-bottom: 0;
    .cart-contents {
      display: none;
    }
    .widget_shopping_cart {
      display: none;
      .woocommerce-mini-cart__empty-message {
        margin: 1.41575em;
      }
      .product_list_widget img {
        margin-left: 1em;
      }
    }
  }
  
  .site-search {
    font-size: .875em;
    clear: both;
    display: none;
    .widget_product_search {
      input {
        &[type=search], &[type=text] {
          padding: 1em 1.41575em;
          line-height: 1;
        }
      }
      form:before {
        top: 1.15em;
        left: 1.15em;
      }
      #searchsubmit {
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute !important;
      }
    }
  }
  
  .storefront-handheld-footer-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
    z-index: 9999;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    ul {
      margin: 0;
      li {
        display: inline-block;
        float: left;
        text-align: center;
        &:last-child > a {
          border-right: 0;
        }
        > a {
          height: 4.235801032em;
          display: block;
          position: relative;
          text-indent: -9999px;
          z-index: 999;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          &:active, &:focus {
            outline: 0;
          }
        }
        &.search {
          .site-search {
            position: absolute;
            bottom: -2em;
            left: 0;
            right: 0;
            -webkit-transition: all,ease,.2s;
            transition: all,ease,.2s;
            padding: 1em;
            z-index: 1;
            display: block;
          }
          &.active .site-search {
            bottom: 100%;
          }
          .site-search, .widget, form {
            margin-bottom: 0;
          }
        }
        &.cart .count {
          text-indent: 0;
          display: block;
          width: 2em;
          height: 2em;
          line-height: 2;
          box-sizing: content-box;
          font-size: .75em;
          position: absolute;
          top: .875em;
          left: 50%;
          border-radius: 100%;
          border: 1px solid;
        }
      }
      &.columns-1 li {
        width: 100%;
        display: block;
        float: none;
      }
      &.columns-2 li {
        width: 50%;
      }
      &.columns-3 li {
        width: 33.33333%;
      }
      &.columns-4 li {
        width: 25%;
      }
      &.columns-5 li {
        width: 20%;
      }
    }
  }
  
  table.shop_table_responsive {
    thead, tbody th {
      display: none;
    }
    tr td {
      display: block;
      text-align: right;
      clear: both;
      &:before {
        content: attr(data-title) ": ";
        font-weight: 600;
        float: left;
      }
      &.product-remove {
        a {
          text-align: left;
        }
        &:before {
          display: none;
        }
      }
      &.actions:before {
        display: none;
      }
      &.download-actions {
        &:before {
          display: none;
        }
        .button {
          display: block;
          text-align: center;
        }
      }
    }
    &.my_account_orders .order-actions {
      text-align: right;
      &:before {
        display: none;
      }
    }
  }
  
  ul.products {
    margin-left: 0;
    margin-bottom: 0;
    clear: both;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    li.product {
      list-style: none;
      margin-left: 0;
      margin-bottom: 4.235801032em;
      text-align: center;
      position: relative;
      .star-rating {
        margin: 0 auto .5407911001em;
      }
      .woocommerce-LoopProduct-link {
        display: block;
      }
      .price {
        display: block;
        color: #43454b;
        font-weight: 400;
        margin-bottom: 1rem;
        ins {
          margin-left: .6180469716em;
          background-color: transparent;
        }
      }
      .woocommerce-loop-product__title, h2, h3 {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: .5407911001em;
      }
      .star-rating {
        font-size: .875em;
      }
      img {
        display: block;
        margin: 0 auto 1.618em;
      }
      &.product-category {
        .woocommerce-loop-category__title, h2, h3 {
          font-size: 1.1em;
        }
        img {
          margin-bottom: 1.618em;
        }
      }
      .button {
        margin-bottom: .236em;
      }
    }
  }
  
  .price del {
    opacity: .5;
    font-weight: 400;
    + ins {
      margin-left: .327em;
    }
  }
  
  .single-product {
    .pswp__button {
      background-color: transparent;
    }
    div.product {
      position: relative;
      overflow: hidden;
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      .images, .summary {
        margin-bottom: 2.617924em;
        margin-top: 0;
      }
      .woocommerce-product-gallery {
        margin-bottom: 2.617924em;
        margin-top: 0;
        position: relative;
        .zoomImg {
          background-color: #fff;
        }
        .woocommerce-product-gallery__trigger {
          position: absolute;
          top: .875em;
          right: .875em;
          display: block;
          height: 2em;
          width: 2em;
          border-radius: 3px;
          z-index: 99;
          text-align: center;
        }
        img {
          margin: 0;
        }
        .flex-viewport {
          margin-bottom: 1.618em;
        }
        .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:not(:only-child):not(.flex-active-slide) {
          margin-left: 1px;
        }
        .flex-control-thumbs {
          margin: 0;
          padding: 0;
          &:after, &:before {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          li {
            list-style: none;
            margin-bottom: 1.618em;
            cursor: pointer;
            img {
              opacity: .5;
              -webkit-transition: all,ease,.2s;
              transition: all,ease,.2s;
              &.flex-active {
                opacity: 1;
              }
            }
            &:hover img {
              opacity: 1;
            }
          }
        }
        &.woocommerce-product-gallery--columns-2 .flex-control-thumbs li {
          width: 42.8571428571%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(2n+1) {
            clear: both;
          }
        }
        &.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
          width: 23.8095238%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(3n+1) {
            clear: both;
          }
        }
        &.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
          width: 14.2857142857%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(4n+1) {
            clear: both;
          }
        }
        &.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
          width: 8.5714285714%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(5n) {
            margin-right: 0;
          }
          &:nth-child(5n+1) {
            clear: both;
          }
        }
      }
      .images {
        .woocommerce-main-image {
          margin-bottom: 1.618em;
          display: block;
        }
        .thumbnails a.zoom {
          display: block;
          width: 22.05%;
          margin-right: 3.8%;
          float: left;
          margin-bottom: 1em;
          &.last {
            margin-right: 0;
          }
          &.first {
            clear: both;
          }
        }
      }
      form.cart {
        margin-bottom: 1.618em;
        padding: 1em 0;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        .quantity {
          float: left;
          margin-right: .875em;
        }
        table.woocommerce-grouped-product-list .woocommerce-grouped-product-list-item__quantity {
          float: none;
          margin-right: 0;
          .quantity {
            margin-right: 0;
          }
        }
      }
      p.price {
        font-size: 1.41575em;
        margin: 1.41575em 0;
      }
      table.variations {
        margin: 0;
        td, th {
          display: list-item;
          padding: 0;
          list-style: none;
          background-color: transparent;
        }
        .value {
          margin-bottom: 1em;
        }
      }
      .single_variation .price {
        margin-bottom: 1em;
        display: block;
      }
      .variations_button {
        padding-top: 1em;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
      }
      .woocommerce-product-rating {
        margin-bottom: 1.618em;
        margin-top: -.875em;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        a {
          font-weight: 600;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        .star-rating {
          float: left;
          margin-right: .6180469716em;
        }
      }
      .product_meta {
        font-size: .875em;
        padding-top: 1em;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        .posted_in, .sku_wrapper, .tagged_as {
          display: block;
        }
        .posted_in:last-child, .sku_wrapper:last-child, .tagged_as:last-child {
          border-bottom: 0;
        }
        a {
          font-weight: 600;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  
  .stock {
    &:empty:before {
      display: none;
    }
    &.in-stock {
      color: #0f834d;
    }
    &.out-of-stock {
      color: #e2401c;
    }
  }
  
  a.reset_variations {
    display: inline-block;
    margin-left: 1em;
  }
  
  #reviews {
    outline: 0;
    #comments {
      padding-top: 0;
    }
    .comment-reply-title {
      display: none;
    }
    .commentlist {
      list-style: none;
      margin-left: 0;
      li {
        margin-bottom: 2.617924em;
        list-style: none;
        clear: both;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        .comment_container {
          border-radius: 3px;
          &:after, &:before {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          .comment-text {
            width: 81.8181818182%;
            float: right;
            margin-right: 0;
            .star-rating {
              float: right;
              font-size: .875em;
              line-height: 1;
              height: 1em;
            }
          }
        }
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
        time {
          opacity: .5;
          font-size: .875em;
        }
        .avatar {
          width: 9.0909090909%;
          float: left;
          margin-right: 9.0909090909%;
          height: auto;
        }
        p.meta {
          margin-bottom: 1em;
          strong {
            line-height: 1;
          }
          time {
            display: block;
          }
          .verified {
            font-style: normal;
            font-size: .875em;
          }
          .woocommerce-review__dash {
            display: none;
          }
        }
        ul.children {
          margin: 0;
          width: 81.8181818182%;
          float: right;
          margin-right: 0;
          padding-top: 1.618em;
          .avatar {
            width: 11.1111111111%;
            float: left;
            margin-right: 11.1111111111%;
          }
          .comment-text {
            width: 77.7777777778%;
            float: right;
            margin-right: 0;
          }
          li {
            margin-bottom: 1.618em;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .comment-form-rating {
      clear: both;
      label {
        display: block;
      }
    }
  }
  
  .woocommerce-breadcrumb {
    margin-left: -1.387rem;
    margin-right: -1.387rem;
    margin-bottom: 1.618em;
    font-size: .875em;
    padding: 1em 1.387rem;
    .breadcrumb-separator {
      display: inline-block;
      padding: 0 .5407911001em;
    }
  }
  
  .product_list_widget {
    list-style: none;
    margin-left: 0;
    li {
      padding: 1em 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      img {
        max-width: 2.617924em;
        float: right;
      }
      > a {
        display: block;
      }
    }
    a:hover {
      color: #2c2d33;
    }
  }
  
  .widget ul.products li.product a {
    text-decoration: none;
  }
  
  .widget_products a {
    display: block;
  }
  
  .widget_shopping_cart {
    .product_list_widget {
      margin-bottom: 0;
      li {
        padding-left: 2em;
        position: relative;
        a.remove {
          position: absolute;
          top: 1em;
          left: 0;
          opacity: 1;
        }
      }
    }
    p {
      &.buttons {
        padding: 1em 0;
        margin: 0;
        text-align: center;
      }
      &.total {
        padding: 1em 0;
        margin: 0;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
    .buttons .button {
      display: block;
      &:nth-child(odd) {
        margin-bottom: .6180469716em;
      }
    }
  }
  
  .site-header .widget_shopping_cart {
    li {
      padding-left: 1.41575em;
      padding-right: 1.41575em;
    }
    p {
      &.buttons, &.total {
        padding-left: 1.41575em;
        padding-right: 1.41575em;
      }
    }
  }
  
  .widget_layered_nav li {
    margin-bottom: .6180469716em;
  }
  
  .widget.woocommerce li .count {
    font-size: 1em;
    float: right;
  }
  
  .widget_price_filter {
    .price_slider {
      margin-bottom: 1em;
    }
    .price_slider_amount {
      text-align: right;
      line-height: 2.4em;
      .button {
        float: left;
      }
    }
    .ui-slider {
      position: relative;
      text-align: left;
      .ui-slider-handle {
        position: absolute;
        top: auto;
        z-index: 2;
        width: 1em;
        height: 1em;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
        cursor: ew-resize;
        outline: 0;
        background: #2c2d33;
        box-sizing: border-box;
        margin-top: -.25em;
        margin-left: auto;
        opacity: 1;
        border: 0;
        &:last-child {
          margin-left: -1em;
        }
        &.ui-state-active, &:hover {
          box-shadow: 0 0 0 0.236em rgba(0, 0, 0, 0.1);
        }
      }
      .ui-slider-range {
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
        border: 0;
        background: #2c2d33;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
      }
    }
    .price_slider_wrapper .ui-widget-content {
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
      background: rgba(0, 0, 0, 0.1);
      border: 0;
    }
    .ui-slider-horizontal {
      height: .5em;
      .ui-slider-range {
        top: 0;
        height: 100%;
      }
      .ui-slider-range-min {
        left: -1px;
      }
      .ui-slider-range-max {
        right: -1px;
      }
    }
  }
  
  table.cart {
    margin-bottom: 3.706325903em;
    .product-quantity {
      .minus, .plus {
        display: none;
      }
    }
    .product-thumbnail {
      img {
        margin: 0 auto;
        max-width: 3.706325903em;
        height: auto;
      }
      &:before {
        display: none;
      }
    }
    tr:first-child td.product-remove {
      border-top-width: 0;
    }
    td {
      &.product-remove {
        padding: 0;
        border-top: 1.618em solid;
        position: relative;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        a.remove {
          position: absolute;
          font-size: 1.41575em;
          top: -.6180469716em;
          right: -.6180469716em;
        }
      }
      &.product-quantity .qty {
        padding: .326em;
        width: 3.706325903em;
      }
      padding: .875em .875em 0;
    }
    th {
      padding: .875em .875em 0;
    }
    td {
      &.product-subtotal {
        padding-bottom: .875em;
      }
      &.actions {
        border-top: 1em solid;
        background-color: transparent;
        padding: 0;
        label {
          display: none;
        }
        .coupon {
          padding-bottom: 1em;
          margin-bottom: 1em;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
        input {
          display: block;
          width: 100%;
          margin: .5407911001em 0;
          &[name=update_cart]:focus {
            outline: 0;
          }
        }
      }
    }
  }
  
  .wc-proceed-to-checkout {
    margin-bottom: 1.618em;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    .button {
      display: block;
      text-align: center;
      margin-bottom: 1em;
      &.checkout-button {
        font-size: 1.41575em;
      }
    }
  }
  
  .woocommerce-shipping-calculator {
    margin: 0;
    > p:first-child {
      margin: 0;
    }
    .shipping-calculator-form {
      padding-top: 1.41575em;
    }
  }
  
  ul#shipping_method {
    list-style: none;
    margin-left: 0;
    li {
      margin-bottom: .2360828548em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    input {
      margin-right: .3342343017em;
    }
  }
  
  .woocommerce-checkout ul#shipping_method {
    margin-bottom: 0;
  }
  
  .checkout_coupon {
    margin-bottom: 2.617924em;
  }
  
  form.checkout {
    position: static !important;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    > .blockUI {
      position: fixed !important;
    }
  }
  
  #payment {
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after, .create-account {
      clear: both;
    }
    .payment_methods {
      margin: 0 !important;
      padding: 0 !important;
      > {
        .wc_payment_method > label, .woocommerce-PaymentMethod > label {
          display: block;
          padding: 1.41575em;
          cursor: pointer;
        }
      }
      li {
        list-style: none !important;
        margin-left: 0;
        position: relative;
        img {
          float: right;
          border: 0;
          padding: 0;
          max-height: 1.618em;
        }
        &:last-child:not(.woocommerce-notice) {
          padding-bottom: 0;
          border-bottom: 0;
        }
        p {
          margin: 0;
        }
        .payment_box {
          padding: 1.41575em;
          fieldset {
            border: 0;
            margin: 0;
            padding: 1em 0 0 0;
            font-size: .875em;
            label {
              width: auto;
              margin-left: 0;
            }
            .form-row {
              margin-bottom: 1em;
              input[type=checkbox] {
                margin-right: 5px;
              }
            }
            #cvv {
              width: 22% !important;
            }
            .help {
              width: 75%;
              float: right;
            }
          }
          .woocommerce-SavedPaymentMethods {
            padding-top: 1em;
            margin: 0;
            li {
              margin-top: .6180469716em;
              input[type=radio] {
                margin-right: .236em;
              }
            }
          }
          ul.wc-saved-payment-methods {
            margin-left: 0;
          }
          .wc-credit-card-form-card-cvc, .wc-credit-card-form-card-expiry {
            font-size: 1.41575em;
          }
          .wc-credit-card-form-card-number {
            font-size: 1.41575em;
            background-repeat: no-repeat;
            background-position: right .6180469716em center;
            background-size: 31px 20px;
            background-image: url(../../../assets/images/credit-cards/unknown.svg);
            &.visa {
              background-image: url(../../../assets/images/credit-cards/visa.svg);
            }
            &.mastercard {
              background-image: url(../../../assets/images/credit-cards/mastercard.svg);
            }
            &.laser {
              background-image: url(../../../assets/images/credit-cards/laser.svg);
            }
            &.dinersclub {
              background-image: url(../../../assets/images/credit-cards/diners.svg);
            }
            &.maestro {
              background-image: url(../../../assets/images/credit-cards/maestro.svg);
            }
            &.jcb {
              background-image: url(../../../assets/images/credit-cards/jcb.svg);
            }
            &.amex {
              background-image: url(../../../assets/images/credit-cards/amex.svg);
            }
            &.discover {
              background-image: url(../../../assets/images/credit-cards/discover.svg);
            }
          }
        }
      }
    }
    .terms {
      width: 100%;
      clear: both;
      float: none;
      margin-bottom: 0;
      margin-bottom: 1em;
      label {
        display: block;
        cursor: pointer;
      }
    }
    .woocommerce-terms-and-conditions {
      padding: 1em;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
      margin-bottom: 16px;
      background-color: rgba(0, 0, 0, 0.05);
    }
    .place-order {
      padding: 1.41575em;
      margin-bottom: 0;
      float: none;
      width: 100%;
      margin-top: 2.617924em;
      .button {
        font-size: 1.41575em;
        width: 100%;
        white-space: pre-wrap;
      }
    }
  }
  
  table.woocommerce-checkout-review-order-table .product-name {
    width: 300px;
    word-wrap: break-word;
  }
  
  .admin-bar .woocommerce-checkout #wc_checkout_add_ons label + br {
    display: none;
  }
  
  .woocommerce-password-strength {
    text-align: left;
    font-weight: 600;
    padding: .5407911001em 0;
    text-align: right;
    font-size: .875em;
    &.strong {
      color: #0f834d;
    }
    &.short, &.bad {
      color: #e2401c;
    }
    &.good {
      color: #3d9cd2;
    }
  }
  
  label.inline {
    display: inline !important;
    margin-left: 1em;
  }
  
  .woocommerce-MyAccount-navigation ul {
    margin-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    li {
      list-style: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      a {
        padding: .875em 0;
        display: block;
      }
    }
  }
  
  ul.order_details {
    list-style: none;
    position: relative;
    margin: 3.706325903em 0;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
      content: "";
      display: block;
      position: absolute;
      top: -16px;
      left: 0;
      width: 100%;
      height: 16px;
      background-size: 0 100%,16px 16px,16px 16px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -16px;
      left: 0;
      width: 100%;
      height: 16px;
      background-size: 0 100%,16px 16px,16px 16px;
    }
    &:after {
      top: auto;
      bottom: -16px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    li {
      padding: 1em 1.618em;
      font-size: .8em;
      text-transform: uppercase;
      &:first-child {
        padding-top: 1.618em;
      }
      &:last-child {
        border: 0;
        padding-bottom: 1.618em;
      }
      strong {
        display: block;
        font-size: 1.41575em;
        text-transform: none;
      }
    }
  }
  
  .my_account_orders .button, .woocommerce-MyAccount-downloads .button {
    padding: .6180469716em .875em;
    font-size: .875em;
    margin-right: .236em;
  }
  
  .woocommerce-MyAccount-content {
    h2 {
      font-size: 2em;
      font-weight: 600;
    }
    #payment .payment_methods {
      margin-bottom: 1.618em !important;
    }
  }
  
  .woocommerce-form__label-for-checkbox {
    cursor: pointer;
    display: block;
    .woocommerce-form__input-checkbox {
      margin-right: .3342343017em;
    }
  }
  
  .form-row {
    label {
      display: block;
    }
    input, select, textarea {
      width: 100%;
    }
    input {
      &[type=checkbox], &[type=radio] {
        width: auto;
      }
    }
    .button {
      width: auto;
    }
    &.create-account label {
      display: inline-block;
    }
    &.woocommerce-validated input.input-text {
      box-shadow: inset 2px 0 0 #0f834d;
    }
    &.woocommerce-invalid input.input-text {
      box-shadow: inset 2px 0 0 #e2401c;
    }
  }
  
  .form-row-last {
    margin-right: 0 !important;
  }
  
  .form-row-wide {
    clear: both;
    width: 100%;
  }
  
  .required {
    border-bottom: 0 !important;
    color: red;
  }
  
  .demo_store {
    position: fixed;
    left: 0;
    bottom: 69px;
    right: 0;
    margin: 0;
    padding: 1em 1.41575em;
    background-color: #3d9cd2;
    color: #fff;
    z-index: 9999;
    a {
      color: #fff;
      font-weight: 400;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &.woocommerce-store-notice__dismiss-link {
        display: block;
      }
    }
  }
  
  .star-rating {
    overflow: hidden;
    position: relative;
    height: 1.618em;
    line-height: 1.618;
    font-size: 1em;
    width: 5.3em;
    font-family: star;
    font-weight: 400;
    &:before {
      content: "\53\53\53\53\53";
      opacity: .25;
      float: left;
      top: 0;
      left: 0;
      position: absolute;
    }
    span {
      overflow: hidden;
      float: left;
      top: 0;
      left: 0;
      position: absolute;
      padding-top: 1.5em;
      &:before {
        content: "\53\53\53\53\53";
        top: 0;
        position: absolute;
        left: 0;
        color: #2c2d33;
      }
    }
  }
  
  p.stars {
    display: inline-block;
    margin: 0;
    a {
      position: relative;
      height: 1em;
      width: 1em;
      text-indent: -999em;
      display: inline-block;
      text-decoration: none;
      margin-right: 1px;
      font-weight: 400;
      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        height: 1em;
        line-height: 1;
        font-family: star;
        content: "\53";
        color: #43454b;
        text-indent: 0;
        opacity: .25;
      }
      &:hover ~ a:before {
        content: "\53";
        color: #43454b;
        opacity: .25;
      }
    }
    &:hover a:before {
      content: "\53";
      color: #96588a;
      opacity: 1;
    }
    &.selected a {
      &.active {
        &:before {
          content: "\53";
          color: #96588a;
          opacity: 1;
        }
        ~ a:before {
          content: "\53";
          color: #43454b;
          opacity: .25;
        }
      }
      &:not(.active):before {
        content: "\53";
        color: #96588a;
        opacity: 1;
      }
    }
  }
  
  .onsale {
    border: 1px solid;
    border-color: #43454b;
    color: #43454b;
    padding: .202em .6180469716em;
    font-size: .875em;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 1em;
    border-radius: 3px;
  }
  
  .quantity .qty {
    width: 4.235801032em;
    text-align: center;
  }
  
  .storefront-sorting {
    font-size: .875em;
    margin-bottom: 2.617924em;
  }
  
  .woocommerce-tabs {
    overflow: hidden;
    padding: 1em 0;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    ul.tabs {
      list-style: none;
      margin-left: 0;
      text-align: left;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      li {
        display: block;
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        position: relative;
        a {
          padding: 1em 0;
          display: block;
        }
      }
    }
    .panel h2:first-of-type {
      font-size: 1.618em;
      margin-bottom: 1em;
    }
  }
  
  .related > h2:first-child, .upsells > h2:first-child {
    font-size: 1.618em;
    margin-bottom: 1em;
  }
  
  .woocommerce-error, .woocommerce-info, .woocommerce-message, .woocommerce-noreviews, p.no-comments {
    padding: 1em 1.618em;
    margin-bottom: 2.617924em;
    background-color: #0f834d;
    margin-left: 0;
    border-radius: 2px;
    color: #fff;
    clear: both;
    border-left: 0.61805em solid rgba(0, 0, 0, 0.15);
  }
  
  .woocommerce-error {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-info {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-message {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-noreviews {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  p.no-comments {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-error:after, .woocommerce-info:after, .woocommerce-message:after, .woocommerce-noreviews:after, p.no-comments:after {
    clear: both;
  }
  
  .woocommerce-error a, .woocommerce-info a, .woocommerce-message a, .woocommerce-noreviews a, p.no-comments a {
    color: #fff;
  }
  
  .woocommerce-error a:hover, .woocommerce-info a:hover, .woocommerce-message a:hover, .woocommerce-noreviews a:hover, p.no-comments a:hover {
    color: #fff;
    opacity: .7;
  }
  
  .woocommerce-error a.button:hover, .woocommerce-info a.button:hover, .woocommerce-message a.button:hover, .woocommerce-noreviews a.button:hover, p.no-comments a.button:hover {
    opacity: 1;
  }
  
  .woocommerce-error .button, .woocommerce-info .button, .woocommerce-message .button, .woocommerce-noreviews .button, p.no-comments .button {
    float: right;
    padding: 0;
    background: 0 0;
    color: #fff;
    box-shadow: none;
    line-height: 1.618;
    padding-left: 1em;
    border-width: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgba(255, 255, 255, 0.25) !important;
    border-radius: 0;
  }
  
  .woocommerce-error .button:hover, .woocommerce-info .button:hover, .woocommerce-message .button:hover, .woocommerce-noreviews .button:hover, p.no-comments .button:hover {
    background: 0 0;
    color: #fff;
    opacity: .7;
  }
  
  .woocommerce-error pre, .woocommerce-info pre, .woocommerce-message pre, .woocommerce-noreviews pre, p.no-comments pre {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .site-content > .col-full > .woocommerce > {
    .woocommerce-error:first-child, .woocommerce-info:first-child, .woocommerce-message:first-child {
      margin-top: 2.617924em;
    }
  }
  
  .woocommerce-error {
    list-style: none;
  }
  
  .woocommerce-info, .woocommerce-noreviews, p.no-comments {
    background-color: #3d9cd2;
  }
  
  .woocommerce-error {
    background-color: #e2401c;
  }
  
  dl.variation {
    font-size: .875em;
    dd {
      margin: 0 0 1em;
    }
  }
  
  .wc-item-meta {
    font-size: .875em;
    margin-left: 0;
    list-style: none;
    li {
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      p, strong {
        display: inline-block;
      }
    }
  }
  
  .woocommerce-MyAccount-content .woocommerce-Pagination {
    text-align: center;
  }
  
  .storefront-product-section {
    margin-bottom: 1.618em;
    .section-title {
      text-align: center;
      margin-bottom: 1em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  @media (min-width: 768px) {
    .demo_store {
      bottom: 0;
      a.woocommerce-store-notice__dismiss-link {
        background: rgba(0, 0, 0, 0.1);
        float: right;
        display: inline-block;
        margin: -1em -1.41575em -1em 0;
        padding: 1em 1.41575em;
      }
    }
    .storefront-handheld-footer-bar {
      display: none;
    }
    .woocommerce-breadcrumb {
      padding: 1.41575em 99em 1.41575em;
      margin-left: -99em;
      margin-right: -99em;
      margin-bottom: 4.235801032em;
    }
    table.shop_table_responsive {
      thead {
        display: table-header-group;
      }
      tbody th {
        display: table-cell;
      }
      tr td {
        display: table-cell;
        &:before {
          display: none;
        }
      }
    }
    .site-header-cart {
      position: relative;
      .cart-contents {
        padding: 1.618em 0;
        display: block;
        position: relative;
        background-color: transparent;
        height: auto;
        width: auto;
        text-indent: 0;
        .amount {
          margin-right: .327em;
        }
        .count {
          font-weight: 400;
          opacity: .5;
          font-size: .875em;
        }
        span {
          display: inline;
        }
      }
      .widget_shopping_cart {
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 999999;
        font-size: .875em;
        left: -999em;
        display: block;
        &.sub-menu--is-touch-device {
          display: none;
          left: 0;
        }
        h2.widgettitle {
          display: none;
        }
        .product_list_widget li a.remove {
          position: relative;
          float: left;
          top: auto;
          &:before {
            text-align: left;
          }
        }
      }
      &.focus .widget_shopping_cart, &:hover .widget_shopping_cart {
        left: 0;
        display: block;
      }
    }
    .site-search {
      display: block;
      form {
        margin: 0;
      }
    }
    ul.products li.product {
      clear: none;
      width: 100%;
      float: left;
      font-size: .875em;
      &.first {
        clear: both !important;
      }
      &.last {
        margin-right: 0 !important;
      }
    }
    .page-template-template-fullwidth-php .site-main .columns-1 ul.products li.product, .page-template-template-homepage-php .site-main .columns-1 ul.products li.product, .storefront-full-width-content .site-main .columns-1 ul.products li.product {
      width: 100%;
      margin-right: 0;
      float: none;
    }
    .page-template-template-fullwidth-php .site-main .columns-2 ul.products li.product, .page-template-template-homepage-php .site-main .columns-2 ul.products li.product, .storefront-full-width-content .site-main .columns-2 ul.products li.product {
      width: 47.8260869565%;
      float: left;
      margin-right: 4.347826087%;
    }
    .page-template-template-fullwidth-php .site-main .columns-3 ul.products li.product, .page-template-template-homepage-php .site-main .columns-3 ul.products li.product, .storefront-full-width-content .site-main .columns-3 ul.products li.product {
      width: 30.4347826087%;
      float: left;
      margin-right: 4.347826087%;
    }
    .page-template-template-fullwidth-php .site-main .columns-4 ul.products li.product, .page-template-template-homepage-php .site-main .columns-4 ul.products li.product, .storefront-full-width-content .site-main .columns-4 ul.products li.product {
      width: 21.7391304348%;
      float: left;
      margin-right: 4.347826087%;
    }
    .page-template-template-fullwidth-php .site-main .columns-5 ul.products li.product, .page-template-template-homepage-php .site-main .columns-5 ul.products li.product, .storefront-full-width-content .site-main .columns-5 ul.products li.product {
      width: 16.9%;
      margin-right: 3.8%;
    }
    .page-template-template-fullwidth-php .site-main .columns-6 ul.products li.product, .page-template-template-homepage-php .site-main .columns-6 ul.products li.product, .storefront-full-width-content .site-main .columns-6 ul.products li.product {
      width: 13.0434782609%;
      float: left;
      margin-right: 4.347826087%;
    }
    .site-main {
      .columns-1 ul.products li.product {
        width: 100%;
        margin-right: 0;
        float: none;
      }
      .columns-2 ul.products li.product {
        width: 48%;
        margin-right: 3.8%;
      }
      .columns-3 ul.products li.product {
        width: 29.4117647059%;
        float: left;
        margin-right: 5.8823529412%;
      }
      .columns-4 ul.products li.product {
        width: 22.05%;
        margin-right: 3.8%;
      }
      .columns-5 ul.products li.product {
        width: 16.9%;
        margin-right: 3.8%;
      }
      .columns-6 ul.products li.product {
        width: 11.7647058824%;
        float: left;
        margin-right: 5.8823529412%;
      }
      ul.products li.product {
        width: 29.4117647059%;
        float: left;
        margin-right: 5.8823529412%;
      }
    }
    .single-product div.product {
      .images {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
        margin-bottom: 3.706325903em;
        .thumbnails {
          &:after, &:before {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          a.zoom {
            width: 14.2857142857%;
            float: left;
            margin-right: 14.2857142857%;
            &.last {
              float: right;
              margin-right: 0;
            }
          }
        }
      }
      .woocommerce-product-gallery {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
        margin-bottom: 3.706325903em;
      }
      .summary {
        width: 52.9411764706%;
        float: right;
        margin-right: 0;
      }
      .woocommerce-tabs {
        clear: both;
      }
    }
    #reviews {
      .comment-form-author {
        width: 47%;
      }
      .comment-form-email {
        width: 47%;
        margin-right: 0;
      }
    }
    .storefront-full-width-content {
      &.single-product {
        div.product {
          .images {
            width: 39.1304347826%;
            float: left;
            margin-right: 4.347826087%;
            margin-bottom: 3.706325903em;
            .thumbnails a.zoom {
              width: 11.1111111111%;
              float: left;
              margin-right: 11.1111111111%;
              &.last {
                margin-right: 0;
              }
            }
          }
          .woocommerce-product-gallery {
            width: 39.1304347826%;
            float: left;
            margin-right: 4.347826087%;
            margin-bottom: 3.706325903em;
            &.woocommerce-product-gallery--columns-2 .flex-control-thumbs li {
              width: 44.4444444444%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
            &.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
              width: 25.9259259333%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(3n) {
                margin-right: 0;
              }
            }
            &.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
              width: 16.6666666667%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(4n) {
                margin-right: 0;
              }
            }
            &.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
              width: 11.1111111111%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(5n) {
                margin-right: 0;
              }
            }
          }
          .summary {
            width: 56.5217391304%;
            float: right;
            margin-right: 0;
            margin-bottom: 3.706325903em;
          }
          .woocommerce-tabs {
            clear: both;
          }
        }
        #reviews .commentlist {
          li {
            .avatar {
              width: 6.6666666667%;
              float: left;
              margin-right: 6.6666666667%;
            }
            .comment_container .comment-text {
              width: 86.6666666667%;
              float: right;
              margin-right: 0;
            }
          }
          ul.children {
            width: 86.6666666667%;
            float: right;
            margin-right: 0;
            .avatar {
              width: 7.6923076923%;
              float: left;
              margin-right: 7.6923076923%;
            }
            .comment-text {
              width: 84.6153846154%;
              float: right;
              margin-right: 0;
            }
          }
        }
        .related ul.products li.product, .upsells ul.products li.product {
          width: 30.4347826087%;
          float: left;
          margin-right: 4.347826087%;
        }
      }
      .woocommerce-products-header, &.woocommerce-account .entry-header, &.woocommerce-cart .entry-header, &.woocommerce-checkout .entry-header {
        text-align: center;
        padding: 0 0 5.9968353111em;
      }
      .woocommerce-products-header h1:last-child, &.woocommerce-account .entry-header h1:last-child, &.woocommerce-cart .entry-header h1:last-child, &.woocommerce-checkout .entry-header h1:last-child {
        margin-bottom: 0;
      }
      .related.products > h2:first-child, .up-sells > h2:first-child {
        text-align: center;
      }
    }
    .woocommerce .page-description {
      margin-bottom: 1.618em;
    }
    .storefront-sorting {
      margin-bottom: 2.617924em;
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      select {
        width: auto;
      }
    }
    .woocommerce-result-count {
      float: left;
      position: relative;
      margin-bottom: 0;
      padding: .327em 0;
    }
    .woocommerce-ordering {
      float: left;
      margin-right: 1em;
      margin-bottom: 0;
      padding: .327em 0;
    }
    .woocommerce-pagination {
      float: right;
      border: 0;
      clear: none;
      padding: 0;
      margin-bottom: 0;
    }
    .woocommerce-cart .hentry, .woocommerce-checkout .hentry {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .page-template-template-fullwidth-php table.cart {
      .product-thumbnail {
        display: table-cell;
      }
      thead {
        font-size: 1em;
        text-indent: 0;
      }
    }
    table.cart {
      td, th {
        padding: 1.618em;
      }
      .qty {
        padding: .875em;
        max-width: 3.632em;
      }
      td.product-remove {
        display: table-cell;
        padding: 1.618em;
        border: 0;
        a.remove {
          float: none;
          position: relative;
          top: auto;
          right: auto;
        }
      }
      .product-quantity {
        .minus, .plus {
          display: inline-block;
        }
      }
      .quantity .qty {
        padding: .6180469716em;
      }
      td.actions {
        text-align: right;
        label {
          display: none;
        }
        .coupon {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
          display: inline-block;
          float: left;
        }
        input {
          display: inline-block;
          width: auto;
          margin: 0;
        }
      }
    }
    .cart-collaterals {
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      h2 {
        font-size: 1.618em;
      }
      .cross-sells {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
        ul.products li.product {
          width: 42.8571428571%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(2n) {
            margin-right: 0 !important;
          }
        }
      }
      .cart_totals, .shipping_calculator {
        width: 52.9411764706%;
        float: right;
        margin-right: 0;
        margin: 0;
        clear: right;
      }
      .cart_totals small.includes_tax {
        display: block;
      }
      .shipping_calculator {
        clear: right;
      }
    }
    .page-template-template-fullwidth-php .cart-collaterals {
      .cross-sells {
        width: 47.8260869565%;
        float: left;
        margin-right: 4.347826087%;
        ul.products li.product {
          width: 45.4545454545%;
          float: left;
          margin-right: 9.0909090909%;
        }
      }
      .cart_totals, .shipping_calculator {
        width: 47.8260869565%;
        float: right;
        margin-right: 0;
      }
    }
    #ship-to-different-address {
      position: relative;
      label {
        font-weight: 300;
      }
      .woocommerce-form__input-checkbox {
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-right: 0;
      }
    }
    .col2-set {
      width: 52.9411764706%;
      float: left;
      margin-right: 5.8823529412%;
      .form-row-first {
        width: 33.3333333333%;
        float: left;
        margin-right: 11.1111111111%;
      }
      .form-row-last {
        width: 55.5555555556%;
        float: right;
        margin-right: 0;
      }
      .col-1, .col-2 {
        margin-bottom: 1.618em;
      }
      + #customer_details {
        display: none;
      }
      .woocommerce-billing-fields {
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
      }
      &.addresses {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        .col-1 {
          width: 47.0588235294%;
          float: left;
          margin-right: 5.8823529412%;
        }
        .col-2 {
          width: 47.0588235294%;
          float: right;
          margin-right: 0;
        }
      }
    }
    #customer_details + #wc_checkout_add_ons {
      width: 41.1764705882%;
      float: right;
      margin-right: 0;
    }
    #wc_checkout_add_ons input[type=radio] {
      float: left;
      margin-right: .5407911001em;
      clear: left;
      margin-top: .35em;
    }
    #order_review, #order_review_heading {
      width: 41.1764705882%;
      float: right;
      margin-right: 0;
      clear: right;
    }
    #order_review {
      -webkit-transition: box-shadow ease .4s;
      transition: box-shadow ease .4s;
    }
    #order_review_heading {
      margin-bottom: 0;
      padding-bottom: .6180469716em;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review, .woocommerce-order-pay #order_review {
      width: 100%;
      float: none;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review #payment h3, .woocommerce-order-pay #order_review #payment h3 {
      padding-left: 1em;
      padding-top: 1em;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review #payment .form-row, .woocommerce-order-pay #order_review #payment .form-row, .page-template-template-fullwidth-php.woocommerce-order-pay #order_review .payment_methods + .form-row, .woocommerce-order-pay #order_review .payment_methods + .form-row {
      padding: 1em;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review .button#place_order, .woocommerce-order-pay #order_review .button#place_order {
      display: block;
      width: 100%;
      font-size: 1.41575em;
    }
    .page-template-template-fullwidth-php {
      .col2-set {
        width: 47.8260869565%;
        float: left;
        margin-right: 4.347826087%;
        .form-row-first {
          width: 45.4545454545%;
          float: left;
          margin-right: 9.0909090909%;
        }
        .form-row-last {
          width: 45.4545454545%;
          float: right;
          margin-right: 0;
        }
        &.addresses {
          .col-1 {
            width: 47.8260869565%;
            float: left;
            margin-right: 4.347826087%;
          }
          .col-2 {
            width: 47.8260869565%;
            float: right;
            margin-right: 0;
          }
        }
      }
      #order_review, #order_review_heading, #customer_details + #wc_checkout_add_ons {
        width: 47.8260869565%;
        float: right;
        margin-right: 0;
      }
    }
    #order_review .shop_table {
      margin-bottom: 2.617924em;
    }
    table + .addresses {
      margin-top: 1.618em;
    }
    .col2-set {
      &#customer_login, &.addresses {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
      }
      &#customer_login .col-1, &.addresses .col-1 {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
      }
      &#customer_login .col-2, &.addresses .col-2 {
        width: 52.9411764706%;
        float: right;
        margin-right: 0;
      }
    }
    .woocommerce-MyAccount-navigation {
      width: 17.6470588235%;
      float: left;
      margin-right: 5.8823529412%;
    }
    .woocommerce-MyAccount-content {
      width: 76.4705882353%;
      float: right;
      margin-right: 0;
      .form-row-first {
        width: 38.4615384615%;
        float: left;
        margin-right: 7.6923076923%;
      }
      .form-row-last {
        width: 53.8461538462%;
        float: right;
        margin-right: 0;
      }
    }
    .left-sidebar:not(.page-template-template-fullwidth-php) {
      .woocommerce-MyAccount-navigation {
        width: 17.6470588235%;
        float: right;
        margin-right: 0;
      }
      .woocommerce-MyAccount-content {
        width: 76.4705882353%;
        float: left;
        margin-right: 5.8823529412%;
      }
    }
    table.my_account_orders {
      font-size: .875em;
    }
    .page-template-template-fullwidth-php {
      .col2-set {
        &#customer_login .col-1, &.addresses .col-1 {
          width: 47.8260869565%;
          float: left;
          margin-right: 4.347826087%;
        }
        &#customer_login .col-2, &.addresses .col-2 {
          width: 47.8260869565%;
          float: right;
          margin-right: 0;
        }
      }
      .woocommerce-MyAccount-navigation {
        width: 21.7391304348%;
        float: left;
        margin-right: 4.347826087%;
      }
      .woocommerce-MyAccount-content {
        width: 73.9130434783%;
        float: right;
        margin-right: 0;
        .form-row-first {
          width: 46.6666666667%;
          float: left;
          margin-right: 6.6666666667%;
        }
        .form-row-last {
          width: 46.6666666667%;
          float: right;
          margin-right: 0;
        }
        .col2-set {
          width: 100%;
          .col-1 {
            width: 46.6666666667%;
            float: left;
            margin-right: 6.6666666667%;
          }
          .col-2 {
            width: 46.6666666667%;
            float: right;
            margin-right: 0;
          }
        }
      }
    }
    .addresses header.title {
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      a {
        float: right;
      }
      h3 {
        float: left;
      }
    }
    .form-row-first {
      width: 47.0588235294%;
      float: left;
      margin-right: 5.8823529412%;
      clear: both;
    }
    .form-row-last {
      width: 47.0588235294%;
      float: right;
      margin-right: 0;
    }
    .page-template-template-fullwidth-php {
      .form-row-first {
        width: 47.8260869565%;
        float: left;
        margin-right: 4.347826087%;
      }
      .form-row-last {
        width: 47.8260869565%;
        float: right;
        margin-right: 0;
      }
    }
    .storefront-full-width-content .woocommerce-tabs {
      ul.tabs {
        width: 30.4347826087%;
        float: left;
        margin-right: 4.347826087%;
      }
      .panel {
        width: 65.2173913043%;
        float: right;
        margin-right: 0;
        margin-bottom: 3.706325903em;
      }
    }
    .woocommerce-tabs {
      padding-top: 2.617924em;
      padding-bottom: 2.617924em;
      ul.tabs {
        width: 29.4117647059%;
        float: left;
        margin-right: 5.8823529412%;
        li.active:after {
          right: 1em;
        }
      }
      .panel {
        width: 64.7058823529%;
        float: right;
        margin-right: 0;
        margin-top: 0;
      }
    }
  }
  
  @media (min-width: 568px) and (max-width: 66.49894em) {
    .woocommerce-breadcrumb {
      margin-left: -2.618rem;
      margin-right: -2.618rem;
      padding-left: 2.618rem;
      padding-right: 2.618rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 900px) {
    body:not(.page-template-template-fullwidth-php) table.cart {
      td, th {
        padding: 1em;
      }
    }
  }
  
  @font-face {
    font-family: star;
    src: url(../../../../../plugins/woocommerce/assets/fonts/star.eot);
    src: url(../../../../../plugins/woocommerce/assets/fonts/star.eot?#iefix) format("embedded-opentype"), url(../../../../../plugins/woocommerce/assets/fonts/star.woff) format("woff"), url(../../../../../plugins/woocommerce/assets/fonts/star.ttf) format("truetype"), url(../../../../../plugins/woocommerce/assets/fonts/star.svg#star) format("svg");
    font-weight: 400;
    font-style: normal;
  }
  
  
  .price ins {
    font-weight: 400;
  }
  
  .woocommerce-active {
    .site-header .col-full {
      position: relative;
    }
    .site-branding {
      float: left;
    }
  }
  
  .site-header-cart {
    list-style-type: none;
    margin-left: 0;
    padding: 0;
    margin-bottom: 0;
    .cart-contents {
      display: none;
    }
    .widget_shopping_cart {
      display: none;
      .woocommerce-mini-cart__empty-message {
        margin: 1.41575em;
      }
      .product_list_widget img {
        margin-left: 1em;
      }
    }
  }
  
  .site-search {
    font-size: .875em;
    clear: both;
    display: none;
    .widget_product_search {
      input {
        &[type=search], &[type=text] {
          padding: 1em 1.41575em;
          line-height: 1;
        }
      }
      form:before {
        top: 1.15em;
        left: 1.15em;
      }
      #searchsubmit {
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute !important;
      }
    }
  }
  
  .storefront-handheld-footer-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
    z-index: 9999;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    ul {
      margin: 0;
      li {
        display: inline-block;
        float: left;
        text-align: center;
        &:last-child > a {
          border-right: 0;
        }
        > a {
          height: 4.235801032em;
          display: block;
          position: relative;
          text-indent: -9999px;
          z-index: 999;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          &:active, &:focus {
            outline: 0;
          }
        }
        &.search {
          .site-search {
            position: absolute;
            bottom: -2em;
            left: 0;
            right: 0;
            -webkit-transition: all,ease,.2s;
            transition: all,ease,.2s;
            padding: 1em;
            z-index: 1;
            display: block;
          }
          &.active .site-search {
            bottom: 100%;
          }
          .site-search, .widget, form {
            margin-bottom: 0;
          }
        }
        &.cart .count {
          text-indent: 0;
          display: block;
          width: 2em;
          height: 2em;
          line-height: 2;
          box-sizing: content-box;
          font-size: .75em;
          position: absolute;
          top: .875em;
          left: 50%;
          border-radius: 100%;
          border: 1px solid;
        }
      }
      &.columns-1 li {
        width: 100%;
        display: block;
        float: none;
      }
      &.columns-2 li {
        width: 50%;
      }
      &.columns-3 li {
        width: 33.33333%;
      }
      &.columns-4 li {
        width: 25%;
      }
      &.columns-5 li {
        width: 20%;
      }
    }
  }
  
  table.shop_table_responsive {
    thead, tbody th {
      display: none;
    }
    tr td {
      display: block;
      text-align: right;
      clear: both;
      &:before {
        content: attr(data-title) ": ";
        font-weight: 600;
        float: left;
      }
      &.product-remove {
        a {
          text-align: left;
        }
        &:before {
          display: none;
        }
      }
      &.actions:before {
        display: none;
      }
      &.download-actions {
        &:before {
          display: none;
        }
        .button {
          display: block;
          text-align: center;
        }
      }
    }
    &.my_account_orders .order-actions {
      text-align: right;
      &:before {
        display: none;
      }
    }
  }
  
  ul.products {
    margin-left: 0;
    margin-bottom: 0;
    clear: both;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    li.product {
      list-style: none;
      margin-left: 0;
      margin-bottom: 4.235801032em;
      text-align: center;
      position: relative;
      .star-rating {
        margin: 0 auto .5407911001em;
      }
      .woocommerce-LoopProduct-link {
        display: block;
      }
      .price {
        display: block;
        color: #43454b;
        font-weight: 400;
        margin-bottom: 1rem;
        ins {
          margin-left: .6180469716em;
          background-color: transparent;
        }
      }
      .woocommerce-loop-product__title, h2, h3 {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: .5407911001em;
      }
      .star-rating {
        font-size: .875em;
      }
      img {
        display: block;
        margin: 0 auto 1.618em;
      }
      &.product-category {
        .woocommerce-loop-category__title, h2, h3 {
          font-size: 1.1em;
        }
        img {
          margin-bottom: 1.618em;
        }
      }
      .button {
        margin-bottom: .236em;
      }
    }
  }
  
  .price del {
    opacity: .5;
    font-weight: 400;
    + ins {
      margin-left: .327em;
    }
  }
  
  .single-product {
    .pswp__button {
      background-color: transparent;
    }
    div.product {
      position: relative;
      overflow: hidden;
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      .images, .summary {
        margin-bottom: 2.617924em;
        margin-top: 0;
      }
      .woocommerce-product-gallery {
        margin-bottom: 2.617924em;
        margin-top: 0;
        position: relative;
        .zoomImg {
          background-color: #fff;
        }
        .woocommerce-product-gallery__trigger {
          position: absolute;
          top: .875em;
          right: .875em;
          display: block;
          height: 2em;
          width: 2em;
          border-radius: 3px;
          z-index: 99;
          text-align: center;
        }
        img {
          margin: 0;
        }
        .flex-viewport {
          margin-bottom: 1.618em;
        }
        .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:not(:only-child):not(.flex-active-slide) {
          margin-left: 1px;
        }
        .flex-control-thumbs {
          margin: 0;
          padding: 0;
          &:after, &:before {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          li {
            list-style: none;
            margin-bottom: 1.618em;
            cursor: pointer;
            img {
              opacity: .5;
              -webkit-transition: all,ease,.2s;
              transition: all,ease,.2s;
              &.flex-active {
                opacity: 1;
              }
            }
            &:hover img {
              opacity: 1;
            }
          }
        }
        &.woocommerce-product-gallery--columns-2 .flex-control-thumbs li {
          width: 42.8571428571%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(2n+1) {
            clear: both;
          }
        }
        &.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
          width: 23.8095238%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(3n+1) {
            clear: both;
          }
        }
        &.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
          width: 14.2857142857%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(4n+1) {
            clear: both;
          }
        }
        &.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
          width: 8.5714285714%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(5n) {
            margin-right: 0;
          }
          &:nth-child(5n+1) {
            clear: both;
          }
        }
      }
      .images {
        .woocommerce-main-image {
          margin-bottom: 1.618em;
          display: block;
        }
        .thumbnails a.zoom {
          display: block;
          width: 22.05%;
          margin-right: 3.8%;
          float: left;
          margin-bottom: 1em;
          &.last {
            margin-right: 0;
          }
          &.first {
            clear: both;
          }
        }
      }
      form.cart {
        margin-bottom: 1.618em;
        padding: 1em 0;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        .quantity {
          float: left;
          margin-right: .875em;
        }
        table.woocommerce-grouped-product-list .woocommerce-grouped-product-list-item__quantity {
          float: none;
          margin-right: 0;
          .quantity {
            margin-right: 0;
          }
        }
      }
      p.price {
        font-size: 1.41575em;
        margin: 1.41575em 0;
      }
      table.variations {
        margin: 0;
        td, th {
          display: list-item;
          padding: 0;
          list-style: none;
          background-color: transparent;
        }
        .value {
          margin-bottom: 1em;
        }
      }
      .single_variation .price {
        margin-bottom: 1em;
        display: block;
      }
      .variations_button {
        padding-top: 1em;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
      }
      .woocommerce-product-rating {
        margin-bottom: 1.618em;
        margin-top: -.875em;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        a {
          font-weight: 600;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        .star-rating {
          float: left;
          margin-right: .6180469716em;
        }
      }
      .product_meta {
        font-size: .875em;
        padding-top: 1em;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        .posted_in, .sku_wrapper, .tagged_as {
          display: block;
        }
        .posted_in:last-child, .sku_wrapper:last-child, .tagged_as:last-child {
          border-bottom: 0;
        }
        a {
          font-weight: 600;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  
  .stock {
    &:empty:before {
      display: none;
    }
    &.in-stock {
      color: #0f834d;
    }
    &.out-of-stock {
      color: #e2401c;
    }
  }
  
  a.reset_variations {
    display: inline-block;
    margin-left: 1em;
  }
  
  #reviews {
    outline: 0;
    #comments {
      padding-top: 0;
    }
    .comment-reply-title {
      display: none;
    }
    .commentlist {
      list-style: none;
      margin-left: 0;
      li {
        margin-bottom: 2.617924em;
        list-style: none;
        clear: both;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        .comment_container {
          border-radius: 3px;
          &:after, &:before {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          .comment-text {
            width: 81.8181818182%;
            float: right;
            margin-right: 0;
            .star-rating {
              float: right;
              font-size: .875em;
              line-height: 1;
              height: 1em;
            }
          }
        }
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
        time {
          opacity: .5;
          font-size: .875em;
        }
        .avatar {
          width: 9.0909090909%;
          float: left;
          margin-right: 9.0909090909%;
          height: auto;
        }
        p.meta {
          margin-bottom: 1em;
          strong {
            line-height: 1;
          }
          time {
            display: block;
          }
          .verified {
            font-style: normal;
            font-size: .875em;
          }
          .woocommerce-review__dash {
            display: none;
          }
        }
        ul.children {
          margin: 0;
          width: 81.8181818182%;
          float: right;
          margin-right: 0;
          padding-top: 1.618em;
          .avatar {
            width: 11.1111111111%;
            float: left;
            margin-right: 11.1111111111%;
          }
          .comment-text {
            width: 77.7777777778%;
            float: right;
            margin-right: 0;
          }
          li {
            margin-bottom: 1.618em;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .comment-form-rating {
      clear: both;
      label {
        display: block;
      }
    }
  }
  
  .woocommerce-breadcrumb {
    margin-left: -1.387rem;
    margin-right: -1.387rem;
    margin-bottom: 1.618em;
    font-size: .875em;
    padding: 1em 1.387rem;
    .breadcrumb-separator {
      display: inline-block;
      padding: 0 .5407911001em;
    }
  }
  
  .product_list_widget {
    list-style: none;
    margin-left: 0;
    li {
      padding: 1em 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      img {
        max-width: 2.617924em;
        float: right;
      }
      > a {
        display: block;
      }
    }
    a:hover {
      color: #2c2d33;
    }
  }
  
  .widget ul.products li.product a {
    text-decoration: none;
  }
  
  .widget_products a {
    display: block;
  }
  
  .widget_shopping_cart {
    .product_list_widget {
      margin-bottom: 0;
      li {
        padding-left: 2em;
        position: relative;
        a.remove {
          position: absolute;
          top: 1em;
          left: 0;
          opacity: 1;
        }
      }
    }
    p {
      &.buttons {
        padding: 1em 0;
        margin: 0;
        text-align: center;
      }
      &.total {
        padding: 1em 0;
        margin: 0;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
    .buttons .button {
      display: block;
      &:nth-child(odd) {
        margin-bottom: .6180469716em;
      }
    }
  }
  
  .site-header .widget_shopping_cart {
    li {
      padding-left: 1.41575em;
      padding-right: 1.41575em;
    }
    p {
      &.buttons, &.total {
        padding-left: 1.41575em;
        padding-right: 1.41575em;
      }
    }
  }
  
  .widget_layered_nav li {
    margin-bottom: .6180469716em;
  }
  
  .widget.woocommerce li .count {
    font-size: 1em;
    float: right;
  }
  
  .widget_price_filter {
    .price_slider {
      margin-bottom: 1em;
    }
    .price_slider_amount {
      text-align: right;
      line-height: 2.4em;
      .button {
        float: left;
      }
    }
    .ui-slider {
      position: relative;
      text-align: left;
      .ui-slider-handle {
        position: absolute;
        top: auto;
        z-index: 2;
        width: 1em;
        height: 1em;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
        cursor: ew-resize;
        outline: 0;
        background: #2c2d33;
        box-sizing: border-box;
        margin-top: -.25em;
        margin-left: auto;
        opacity: 1;
        border: 0;
        &:last-child {
          margin-left: -1em;
        }
        &.ui-state-active, &:hover {
          box-shadow: 0 0 0 0.236em rgba(0, 0, 0, 0.1);
        }
      }
      .ui-slider-range {
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
        border: 0;
        background: #2c2d33;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
      }
    }
    .price_slider_wrapper .ui-widget-content {
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
      background: rgba(0, 0, 0, 0.1);
      border: 0;
    }
    .ui-slider-horizontal {
      height: .5em;
      .ui-slider-range {
        top: 0;
        height: 100%;
      }
      .ui-slider-range-min {
        left: -1px;
      }
      .ui-slider-range-max {
        right: -1px;
      }
    }
  }
  
  table.cart {
    margin-bottom: 3.706325903em;
    .product-quantity {
      .minus, .plus {
        display: none;
      }
    }
    .product-thumbnail {
      img {
        margin: 0 auto;
        max-width: 3.706325903em;
        height: auto;
      }
      &:before {
        display: none;
      }
    }
    tr:first-child td.product-remove {
      border-top-width: 0;
    }
    td {
      &.product-remove {
        padding: 0;
        border-top: 1.618em solid;
        position: relative;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        a.remove {
          position: absolute;
          font-size: 1.41575em;
          top: -.6180469716em;
          right: -.6180469716em;
        }
      }
      &.product-quantity .qty {
        padding: .326em;
        width: 3.706325903em;
      }
      padding: .875em .875em 0;
    }
    th {
      padding: .875em .875em 0;
    }
    td {
      &.product-subtotal {
        padding-bottom: .875em;
      }
      &.actions {
        border-top: 1em solid;
        background-color: transparent;
        padding: 0;
        label {
          display: none;
        }
        .coupon {
          padding-bottom: 1em;
          margin-bottom: 1em;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
        input {
          display: block;
          width: 100%;
          margin: .5407911001em 0;
          &[name=update_cart]:focus {
            outline: 0;
          }
        }
      }
    }
  }
  
  .wc-proceed-to-checkout {
    margin-bottom: 1.618em;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    .button {
      display: block;
      text-align: center;
      margin-bottom: 1em;
      &.checkout-button {
        font-size: 1.41575em;
      }
    }
  }
  
  .woocommerce-shipping-calculator {
    margin: 0;
    > p:first-child {
      margin: 0;
    }
    .shipping-calculator-form {
      padding-top: 1.41575em;
    }
  }
  
  ul#shipping_method {
    list-style: none;
    margin-left: 0;
    li {
      margin-bottom: .2360828548em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    input {
      margin-right: .3342343017em;
    }
  }
  
  .woocommerce-checkout ul#shipping_method {
    margin-bottom: 0;
  }
  
  .checkout_coupon {
    margin-bottom: 2.617924em;
  }
  
  form.checkout {
    position: static !important;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    > .blockUI {
      position: fixed !important;
    }
  }
  
  #payment {
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after, .create-account {
      clear: both;
    }
    .payment_methods {
      margin: 0 !important;
      padding: 0 !important;
      > {
        .wc_payment_method > label, .woocommerce-PaymentMethod > label {
          display: block;
          padding: 1.41575em;
          cursor: pointer;
        }
      }
      li {
        list-style: none !important;
        margin-left: 0;
        position: relative;
        img {
          float: right;
          border: 0;
          padding: 0;
          max-height: 1.618em;
        }
        &:last-child:not(.woocommerce-notice) {
          padding-bottom: 0;
          border-bottom: 0;
        }
        p {
          margin: 0;
        }
        .payment_box {
          padding: 1.41575em;
          fieldset {
            border: 0;
            margin: 0;
            padding: 1em 0 0 0;
            font-size: .875em;
            label {
              width: auto;
              margin-left: 0;
            }
            .form-row {
              margin-bottom: 1em;
              input[type=checkbox] {
                margin-right: 5px;
              }
            }
            #cvv {
              width: 22% !important;
            }
            .help {
              width: 75%;
              float: right;
            }
          }
          .woocommerce-SavedPaymentMethods {
            padding-top: 1em;
            margin: 0;
            li {
              margin-top: .6180469716em;
              input[type=radio] {
                margin-right: .236em;
              }
            }
          }
          ul.wc-saved-payment-methods {
            margin-left: 0;
          }
          .wc-credit-card-form-card-cvc, .wc-credit-card-form-card-expiry {
            font-size: 1.41575em;
          }
          .wc-credit-card-form-card-number {
            font-size: 1.41575em;
            background-repeat: no-repeat;
            background-position: right .6180469716em center;
            background-size: 31px 20px;
            background-image: url(../../../assets/images/credit-cards/unknown.svg);
            &.visa {
              background-image: url(../../../assets/images/credit-cards/visa.svg);
            }
            &.mastercard {
              background-image: url(../../../assets/images/credit-cards/mastercard.svg);
            }
            &.laser {
              background-image: url(../../../assets/images/credit-cards/laser.svg);
            }
            &.dinersclub {
              background-image: url(../../../assets/images/credit-cards/diners.svg);
            }
            &.maestro {
              background-image: url(../../../assets/images/credit-cards/maestro.svg);
            }
            &.jcb {
              background-image: url(../../../assets/images/credit-cards/jcb.svg);
            }
            &.amex {
              background-image: url(../../../assets/images/credit-cards/amex.svg);
            }
            &.discover {
              background-image: url(../../../assets/images/credit-cards/discover.svg);
            }
          }
        }
      }
    }
    .terms {
      width: 100%;
      clear: both;
      float: none;
      margin-bottom: 0;
      margin-bottom: 1em;
      label {
        display: block;
        cursor: pointer;
      }
    }
    .woocommerce-terms-and-conditions {
      padding: 1em;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
      margin-bottom: 16px;
      background-color: rgba(0, 0, 0, 0.05);
    }
    .place-order {
      padding: 1.41575em;
      margin-bottom: 0;
      float: none;
      width: 100%;
      margin-top: 2.617924em;
      .button {
        font-size: 1.41575em;
        width: 100%;
        white-space: pre-wrap;
      }
    }
  }
  
  table.woocommerce-checkout-review-order-table .product-name {
    width: 300px;
    word-wrap: break-word;
  }
  
  .admin-bar .woocommerce-checkout #wc_checkout_add_ons label + br {
    display: none;
  }
  
  .woocommerce-password-strength {
    text-align: left;
    font-weight: 600;
    padding: .5407911001em 0;
    text-align: right;
    font-size: .875em;
    &.strong {
      color: #0f834d;
    }
    &.short, &.bad {
      color: #e2401c;
    }
    &.good {
      color: #3d9cd2;
    }
  }
  
  label.inline {
    display: inline !important;
    margin-left: 1em;
  }
  
  .woocommerce-MyAccount-navigation ul {
    margin-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    li {
      list-style: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      a {
        padding: .875em 0;
        display: block;
      }
    }
  }
  
  ul.order_details {
    list-style: none;
    position: relative;
    margin: 3.706325903em 0;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
      content: "";
      display: block;
      position: absolute;
      top: -16px;
      left: 0;
      width: 100%;
      height: 16px;
      background-size: 0 100%,16px 16px,16px 16px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -16px;
      left: 0;
      width: 100%;
      height: 16px;
      background-size: 0 100%,16px 16px,16px 16px;
    }
    &:after {
      top: auto;
      bottom: -16px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    li {
      padding: 1em 1.618em;
      font-size: .8em;
      text-transform: uppercase;
      &:first-child {
        padding-top: 1.618em;
      }
      &:last-child {
        border: 0;
        padding-bottom: 1.618em;
      }
      strong {
        display: block;
        font-size: 1.41575em;
        text-transform: none;
      }
    }
  }
  
  .my_account_orders .button, .woocommerce-MyAccount-downloads .button {
    padding: .6180469716em .875em;
    font-size: .875em;
    margin-right: .236em;
  }
  
  .woocommerce-MyAccount-content {
    h2 {
      font-size: 2em;
      font-weight: 600;
    }
    #payment .payment_methods {
      margin-bottom: 1.618em !important;
    }
  }
  
  .woocommerce-form__label-for-checkbox {
    cursor: pointer;
    display: block;
    .woocommerce-form__input-checkbox {
      margin-right: .3342343017em;
    }
  }
  
  .form-row {
    label {
      display: block;
    }
    input, select, textarea {
      width: 100%;
    }
    input {
      &[type=checkbox], &[type=radio] {
        width: auto;
      }
    }
    .button {
      width: auto;
    }
    &.create-account label {
      display: inline-block;
    }
    &.woocommerce-validated input.input-text {
      box-shadow: inset 2px 0 0 #0f834d;
    }
    &.woocommerce-invalid input.input-text {
      box-shadow: inset 2px 0 0 #e2401c;
    }
  }
  
  .form-row-last {
    margin-right: 0 !important;
  }
  
  .form-row-wide {
    clear: both;
    width: 100%;
  }
  
  .required {
    border-bottom: 0 !important;
    color: red;
  }
  
  .demo_store {
    position: fixed;
    left: 0;
    bottom: 69px;
    right: 0;
    margin: 0;
    padding: 1em 1.41575em;
    background-color: #3d9cd2;
    color: #fff;
    z-index: 9999;
    a {
      color: #fff;
      font-weight: 400;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &.woocommerce-store-notice__dismiss-link {
        display: block;
      }
    }
  }
  
  .star-rating {
    overflow: hidden;
    position: relative;
    height: 1.618em;
    line-height: 1.618;
    font-size: 1em;
    width: 5.3em;
    font-family: star;
    font-weight: 400;
    &:before {
      content: "\53\53\53\53\53";
      opacity: .25;
      float: left;
      top: 0;
      left: 0;
      position: absolute;
    }
    span {
      overflow: hidden;
      float: left;
      top: 0;
      left: 0;
      position: absolute;
      padding-top: 1.5em;
      &:before {
        content: "\53\53\53\53\53";
        top: 0;
        position: absolute;
        left: 0;
        color: #2c2d33;
      }
    }
  }
  
  p.stars {
    display: inline-block;
    margin: 0;
    a {
      position: relative;
      height: 1em;
      width: 1em;
      text-indent: -999em;
      display: inline-block;
      text-decoration: none;
      margin-right: 1px;
      font-weight: 400;
      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        height: 1em;
        line-height: 1;
        font-family: star;
        content: "\53";
        color: #43454b;
        text-indent: 0;
        opacity: .25;
      }
      &:hover ~ a:before {
        content: "\53";
        color: #43454b;
        opacity: .25;
      }
    }
    &:hover a:before {
      content: "\53";
      color: #96588a;
      opacity: 1;
    }
    &.selected a {
      &.active {
        &:before {
          content: "\53";
          color: #96588a;
          opacity: 1;
        }
        ~ a:before {
          content: "\53";
          color: #43454b;
          opacity: .25;
        }
      }
      &:not(.active):before {
        content: "\53";
        color: #96588a;
        opacity: 1;
      }
    }
  }
  
  .onsale {
    border: 1px solid;
    border-color: #43454b;
    color: #43454b;
    padding: .202em .6180469716em;
    font-size: .875em;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 1em;
    border-radius: 3px;
  }
  
  .quantity .qty {
    width: 4.235801032em;
    text-align: center;
  }
  
  .storefront-sorting {
    font-size: .875em;
    margin-bottom: 2.617924em;
  }
  
  .woocommerce-tabs {
    overflow: hidden;
    padding: 1em 0;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    ul.tabs {
      list-style: none;
      margin-left: 0;
      text-align: left;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      li {
        display: block;
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        position: relative;
        a {
          padding: 1em 0;
          display: block;
        }
      }
    }
    .panel h2:first-of-type {
      font-size: 1.618em;
      margin-bottom: 1em;
    }
  }
  
  .related > h2:first-child, .upsells > h2:first-child {
    font-size: 1.618em;
    margin-bottom: 1em;
  }
  
  .woocommerce-error, .woocommerce-info, .woocommerce-message, .woocommerce-noreviews, p.no-comments {
    padding: 1em 1.618em;
    margin-bottom: 2.617924em;
    background-color: #0f834d;
    margin-left: 0;
    border-radius: 2px;
    color: #fff;
    clear: both;
    border-left: 0.61805em solid rgba(0, 0, 0, 0.15);
  }
  
  .woocommerce-error {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-info {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-message {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-noreviews {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  p.no-comments {
    &:after, &:before {
      content: "";
      display: table;
    }
  }
  
  .woocommerce-error:after, .woocommerce-info:after, .woocommerce-message:after, .woocommerce-noreviews:after, p.no-comments:after {
    clear: both;
  }
  
  .woocommerce-error a, .woocommerce-info a, .woocommerce-message a, .woocommerce-noreviews a, p.no-comments a {
    color: #fff;
  }
  
  .woocommerce-error a:hover, .woocommerce-info a:hover, .woocommerce-message a:hover, .woocommerce-noreviews a:hover, p.no-comments a:hover {
    color: #fff;
    opacity: .7;
  }
  
  .woocommerce-error a.button:hover, .woocommerce-info a.button:hover, .woocommerce-message a.button:hover, .woocommerce-noreviews a.button:hover, p.no-comments a.button:hover {
    opacity: 1;
  }
  
  .woocommerce-error .button, .woocommerce-info .button, .woocommerce-message .button, .woocommerce-noreviews .button, p.no-comments .button {
    float: right;
    padding: 0;
    background: 0 0;
    color: #fff;
    box-shadow: none;
    line-height: 1.618;
    padding-left: 1em;
    border-width: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgba(255, 255, 255, 0.25) !important;
    border-radius: 0;
  }
  
  .woocommerce-error .button:hover, .woocommerce-info .button:hover, .woocommerce-message .button:hover, .woocommerce-noreviews .button:hover, p.no-comments .button:hover {
    background: 0 0;
    color: #fff;
    opacity: .7;
  }
  
  .woocommerce-error pre, .woocommerce-info pre, .woocommerce-message pre, .woocommerce-noreviews pre, p.no-comments pre {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .site-content > .col-full > .woocommerce > {
    .woocommerce-error:first-child, .woocommerce-info:first-child, .woocommerce-message:first-child {
      margin-top: 2.617924em;
    }
  }
  
  .woocommerce-error {
    list-style: none;
  }
  
  .woocommerce-info, .woocommerce-noreviews, p.no-comments {
    background-color: #3d9cd2;
  }
  
  .woocommerce-error {
    background-color: #e2401c;
  }
  
  dl.variation {
    font-size: .875em;
    dd {
      margin: 0 0 1em;
    }
  }
  
  .wc-item-meta {
    font-size: .875em;
    margin-left: 0;
    list-style: none;
    li {
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      p, strong {
        display: inline-block;
      }
    }
  }
  
  .woocommerce-MyAccount-content .woocommerce-Pagination {
    text-align: center;
  }
  
  .storefront-product-section {
    margin-bottom: 1.618em;
    .section-title {
      text-align: center;
      margin-bottom: 1em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  @media (min-width: 768px) {
    .demo_store {
      bottom: 0;
      a.woocommerce-store-notice__dismiss-link {
        background: rgba(0, 0, 0, 0.1);
        float: right;
        display: inline-block;
        margin: -1em -1.41575em -1em 0;
        padding: 1em 1.41575em;
      }
    }
    .storefront-handheld-footer-bar {
      display: none;
    }
    .woocommerce-breadcrumb {
      padding: 1.41575em 99em 1.41575em;
      margin-left: -99em;
      margin-right: -99em;
      margin-bottom: 4.235801032em;
    }
    table.shop_table_responsive {
      thead {
        display: table-header-group;
      }
      tbody th {
        display: table-cell;
      }
      tr td {
        display: table-cell;
        &:before {
          display: none;
        }
      }
    }
    .site-header-cart {
      position: relative;
      .cart-contents {
        padding: 1.618em 0;
        display: block;
        position: relative;
        background-color: transparent;
        height: auto;
        width: auto;
        text-indent: 0;
        .amount {
          margin-right: .327em;
        }
        .count {
          font-weight: 400;
          opacity: .5;
          font-size: .875em;
        }
        span {
          display: inline;
        }
      }
      .widget_shopping_cart {
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 999999;
        font-size: .875em;
        left: -999em;
        display: block;
        &.sub-menu--is-touch-device {
          display: none;
          left: 0;
        }
        h2.widgettitle {
          display: none;
        }
        .product_list_widget li a.remove {
          position: relative;
          float: left;
          top: auto;
          &:before {
            text-align: left;
          }
        }
      }
      &.focus .widget_shopping_cart, &:hover .widget_shopping_cart {
        left: 0;
        display: block;
      }
    }
    .site-search {
      display: block;
      form {
        margin: 0;
      }
    }
    ul.products li.product {
      clear: none;
      width: 100%;
      float: left;
      font-size: .875em;
      &.first {
        clear: both !important;
      }
      &.last {
        margin-right: 0 !important;
      }
    }
    .page-template-template-fullwidth-php .site-main .columns-1 ul.products li.product, .page-template-template-homepage-php .site-main .columns-1 ul.products li.product, .storefront-full-width-content .site-main .columns-1 ul.products li.product {
      width: 100%;
      margin-right: 0;
      float: none;
    }
    .page-template-template-fullwidth-php .site-main .columns-2 ul.products li.product, .page-template-template-homepage-php .site-main .columns-2 ul.products li.product, .storefront-full-width-content .site-main .columns-2 ul.products li.product {
      width: 47.8260869565%;
      float: left;
      margin-right: 4.347826087%;
    }
    .page-template-template-fullwidth-php .site-main .columns-3 ul.products li.product, .page-template-template-homepage-php .site-main .columns-3 ul.products li.product, .storefront-full-width-content .site-main .columns-3 ul.products li.product {
      width: 30.4347826087%;
      float: left;
      margin-right: 4.347826087%;
    }
    .page-template-template-fullwidth-php .site-main .columns-4 ul.products li.product, .page-template-template-homepage-php .site-main .columns-4 ul.products li.product, .storefront-full-width-content .site-main .columns-4 ul.products li.product {
      width: 21.7391304348%;
      float: left;
      margin-right: 4.347826087%;
    }
    .page-template-template-fullwidth-php .site-main .columns-5 ul.products li.product, .page-template-template-homepage-php .site-main .columns-5 ul.products li.product, .storefront-full-width-content .site-main .columns-5 ul.products li.product {
      width: 16.9%;
      margin-right: 3.8%;
    }
    .page-template-template-fullwidth-php .site-main .columns-6 ul.products li.product, .page-template-template-homepage-php .site-main .columns-6 ul.products li.product, .storefront-full-width-content .site-main .columns-6 ul.products li.product {
      width: 13.0434782609%;
      float: left;
      margin-right: 4.347826087%;
    }
    .site-main {
      .columns-1 ul.products li.product {
        width: 100%;
        margin-right: 0;
        float: none;
      }
      .columns-2 ul.products li.product {
        width: 48%;
        margin-right: 3.8%;
      }
      .columns-3 ul.products li.product {
        width: 29.4117647059%;
        float: left;
        margin-right: 5.8823529412%;
      }
      .columns-4 ul.products li.product {
        width: 22.05%;
        margin-right: 3.8%;
      }
      .columns-5 ul.products li.product {
        width: 16.9%;
        margin-right: 3.8%;
      }
      .columns-6 ul.products li.product {
        width: 11.7647058824%;
        float: left;
        margin-right: 5.8823529412%;
      }
      ul.products li.product {
        width: 29.4117647059%;
        float: left;
        margin-right: 5.8823529412%;
      }
    }
    .single-product div.product {
      .images {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
        margin-bottom: 3.706325903em;
        .thumbnails {
          &:after, &:before {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          a.zoom {
            width: 14.2857142857%;
            float: left;
            margin-right: 14.2857142857%;
            &.last {
              float: right;
              margin-right: 0;
            }
          }
        }
      }
      .woocommerce-product-gallery {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
        margin-bottom: 3.706325903em;
      }
      .summary {
        width: 52.9411764706%;
        float: right;
        margin-right: 0;
      }
      .woocommerce-tabs {
        clear: both;
      }
    }
    #reviews {
      .comment-form-author {
        width: 47%;
      }
      .comment-form-email {
        width: 47%;
        margin-right: 0;
      }
    }
    .storefront-full-width-content {
      &.single-product {
        div.product {
          .images {
            width: 39.1304347826%;
            float: left;
            margin-right: 4.347826087%;
            margin-bottom: 3.706325903em;
            .thumbnails a.zoom {
              width: 11.1111111111%;
              float: left;
              margin-right: 11.1111111111%;
              &.last {
                margin-right: 0;
              }
            }
          }
          .woocommerce-product-gallery {
            width: 39.1304347826%;
            float: left;
            margin-right: 4.347826087%;
            margin-bottom: 3.706325903em;
            &.woocommerce-product-gallery--columns-2 .flex-control-thumbs li {
              width: 44.4444444444%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
            &.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
              width: 25.9259259333%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(3n) {
                margin-right: 0;
              }
            }
            &.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
              width: 16.6666666667%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(4n) {
                margin-right: 0;
              }
            }
            &.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
              width: 11.1111111111%;
              float: left;
              margin-right: 11.1111111111%;
              &:nth-child(5n) {
                margin-right: 0;
              }
            }
          }
          .summary {
            width: 56.5217391304%;
            float: right;
            margin-right: 0;
            margin-bottom: 3.706325903em;
          }
          .woocommerce-tabs {
            clear: both;
          }
        }
        #reviews .commentlist {
          li {
            .avatar {
              width: 6.6666666667%;
              float: left;
              margin-right: 6.6666666667%;
            }
            .comment_container .comment-text {
              width: 86.6666666667%;
              float: right;
              margin-right: 0;
            }
          }
          ul.children {
            width: 86.6666666667%;
            float: right;
            margin-right: 0;
            .avatar {
              width: 7.6923076923%;
              float: left;
              margin-right: 7.6923076923%;
            }
            .comment-text {
              width: 84.6153846154%;
              float: right;
              margin-right: 0;
            }
          }
        }
        .related ul.products li.product, .upsells ul.products li.product {
          width: 30.4347826087%;
          float: left;
          margin-right: 4.347826087%;
        }
      }
      .woocommerce-products-header, &.woocommerce-account .entry-header, &.woocommerce-cart .entry-header, &.woocommerce-checkout .entry-header {
        text-align: center;
        padding: 0 0 5.9968353111em;
      }
      .woocommerce-products-header h1:last-child, &.woocommerce-account .entry-header h1:last-child, &.woocommerce-cart .entry-header h1:last-child, &.woocommerce-checkout .entry-header h1:last-child {
        margin-bottom: 0;
      }
      .related.products > h2:first-child, .up-sells > h2:first-child {
        text-align: center;
      }
    }
    .woocommerce .page-description {
      margin-bottom: 1.618em;
    }
    .storefront-sorting {
      margin-bottom: 2.617924em;
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      select {
        width: auto;
      }
    }
    .woocommerce-result-count {
      float: left;
      position: relative;
      margin-bottom: 0;
      padding: .327em 0;
    }
    .woocommerce-ordering {
      float: left;
      margin-right: 1em;
      margin-bottom: 0;
      padding: .327em 0;
    }
    .woocommerce-pagination {
      float: right;
      border: 0;
      clear: none;
      padding: 0;
      margin-bottom: 0;
    }
    .woocommerce-cart .hentry, .woocommerce-checkout .hentry {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .page-template-template-fullwidth-php table.cart {
      .product-thumbnail {
        display: table-cell;
      }
      thead {
        font-size: 1em;
        text-indent: 0;
      }
    }
    table.cart {
      td, th {
        padding: 1.618em;
      }
      .qty {
        padding: .875em;
        max-width: 3.632em;
      }
      td.product-remove {
        display: table-cell;
        padding: 1.618em;
        border: 0;
        a.remove {
          float: none;
          position: relative;
          top: auto;
          right: auto;
        }
      }
      .product-quantity {
        .minus, .plus {
          display: inline-block;
        }
      }
      .quantity .qty {
        padding: .6180469716em;
      }
      td.actions {
        text-align: right;
        label {
          display: none;
        }
        .coupon {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
          display: inline-block;
          float: left;
        }
        input {
          display: inline-block;
          width: auto;
          margin: 0;
        }
      }
    }
    .cart-collaterals {
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      h2 {
        font-size: 1.618em;
      }
      .cross-sells {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
        ul.products li.product {
          width: 42.8571428571%;
          float: left;
          margin-right: 14.2857142857%;
          &:nth-child(2n) {
            margin-right: 0 !important;
          }
        }
      }
      .cart_totals, .shipping_calculator {
        width: 52.9411764706%;
        float: right;
        margin-right: 0;
        margin: 0;
        clear: right;
      }
      .cart_totals small.includes_tax {
        display: block;
      }
      .shipping_calculator {
        clear: right;
      }
    }
    .page-template-template-fullwidth-php .cart-collaterals {
      .cross-sells {
        width: 47.8260869565%;
        float: left;
        margin-right: 4.347826087%;
        ul.products li.product {
          width: 45.4545454545%;
          float: left;
          margin-right: 9.0909090909%;
        }
      }
      .cart_totals, .shipping_calculator {
        width: 47.8260869565%;
        float: right;
        margin-right: 0;
      }
    }
    #ship-to-different-address {
      position: relative;
      label {
        font-weight: 300;
      }
      .woocommerce-form__input-checkbox {
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-right: 0;
      }
    }
    .col2-set {
      width: 52.9411764706%;
      float: left;
      margin-right: 5.8823529412%;
      .form-row-first {
        width: 33.3333333333%;
        float: left;
        margin-right: 11.1111111111%;
      }
      .form-row-last {
        width: 55.5555555556%;
        float: right;
        margin-right: 0;
      }
      .col-1, .col-2 {
        margin-bottom: 1.618em;
      }
      + #customer_details {
        display: none;
      }
      .woocommerce-billing-fields {
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
      }
      &.addresses {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        .col-1 {
          width: 47.0588235294%;
          float: left;
          margin-right: 5.8823529412%;
        }
        .col-2 {
          width: 47.0588235294%;
          float: right;
          margin-right: 0;
        }
      }
    }
    #customer_details + #wc_checkout_add_ons {
      width: 41.1764705882%;
      float: right;
      margin-right: 0;
    }
    #wc_checkout_add_ons input[type=radio] {
      float: left;
      margin-right: .5407911001em;
      clear: left;
      margin-top: .35em;
    }
    #order_review, #order_review_heading {
      width: 41.1764705882%;
      float: right;
      margin-right: 0;
      clear: right;
    }
    #order_review {
      -webkit-transition: box-shadow ease .4s;
      transition: box-shadow ease .4s;
    }
    #order_review_heading {
      margin-bottom: 0;
      padding-bottom: .6180469716em;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review, .woocommerce-order-pay #order_review {
      width: 100%;
      float: none;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review #payment h3, .woocommerce-order-pay #order_review #payment h3 {
      padding-left: 1em;
      padding-top: 1em;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review #payment .form-row, .woocommerce-order-pay #order_review #payment .form-row, .page-template-template-fullwidth-php.woocommerce-order-pay #order_review .payment_methods + .form-row, .woocommerce-order-pay #order_review .payment_methods + .form-row {
      padding: 1em;
    }
    .page-template-template-fullwidth-php.woocommerce-order-pay #order_review .button#place_order, .woocommerce-order-pay #order_review .button#place_order {
      display: block;
      width: 100%;
      font-size: 1.41575em;
    }
    .page-template-template-fullwidth-php {
      .col2-set {
        width: 47.8260869565%;
        float: left;
        margin-right: 4.347826087%;
        .form-row-first {
          width: 45.4545454545%;
          float: left;
          margin-right: 9.0909090909%;
        }
        .form-row-last {
          width: 45.4545454545%;
          float: right;
          margin-right: 0;
        }
        &.addresses {
          .col-1 {
            width: 47.8260869565%;
            float: left;
            margin-right: 4.347826087%;
          }
          .col-2 {
            width: 47.8260869565%;
            float: right;
            margin-right: 0;
          }
        }
      }
      #order_review, #order_review_heading, #customer_details + #wc_checkout_add_ons {
        width: 47.8260869565%;
        float: right;
        margin-right: 0;
      }
    }
    #order_review .shop_table {
      margin-bottom: 2.617924em;
    }
    table + .addresses {
      margin-top: 1.618em;
    }
    .col2-set {
      &#customer_login, &.addresses {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
      }
      &#customer_login .col-1, &.addresses .col-1 {
        width: 41.1764705882%;
        float: left;
        margin-right: 5.8823529412%;
      }
      &#customer_login .col-2, &.addresses .col-2 {
        width: 52.9411764706%;
        float: right;
        margin-right: 0;
      }
    }
    .woocommerce-MyAccount-navigation {
      width: 17.6470588235%;
      float: left;
      margin-right: 5.8823529412%;
    }
    .woocommerce-MyAccount-content {
      width: 76.4705882353%;
      float: right;
      margin-right: 0;
      .form-row-first {
        width: 38.4615384615%;
        float: left;
        margin-right: 7.6923076923%;
      }
      .form-row-last {
        width: 53.8461538462%;
        float: right;
        margin-right: 0;
      }
    }
    .left-sidebar:not(.page-template-template-fullwidth-php) {
      .woocommerce-MyAccount-navigation {
        width: 17.6470588235%;
        float: right;
        margin-right: 0;
      }
      .woocommerce-MyAccount-content {
        width: 76.4705882353%;
        float: left;
        margin-right: 5.8823529412%;
      }
    }
    table.my_account_orders {
      font-size: .875em;
    }
    .page-template-template-fullwidth-php {
      .col2-set {
        &#customer_login .col-1, &.addresses .col-1 {
          width: 47.8260869565%;
          float: left;
          margin-right: 4.347826087%;
        }
        &#customer_login .col-2, &.addresses .col-2 {
          width: 47.8260869565%;
          float: right;
          margin-right: 0;
        }
      }
      .woocommerce-MyAccount-navigation {
        width: 21.7391304348%;
        float: left;
        margin-right: 4.347826087%;
      }
      .woocommerce-MyAccount-content {
        width: 73.9130434783%;
        float: right;
        margin-right: 0;
        .form-row-first {
          width: 46.6666666667%;
          float: left;
          margin-right: 6.6666666667%;
        }
        .form-row-last {
          width: 46.6666666667%;
          float: right;
          margin-right: 0;
        }
        .col2-set {
          width: 100%;
          .col-1 {
            width: 46.6666666667%;
            float: left;
            margin-right: 6.6666666667%;
          }
          .col-2 {
            width: 46.6666666667%;
            float: right;
            margin-right: 0;
          }
        }
      }
    }
    .addresses header.title {
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      a {
        float: right;
      }
      h3 {
        float: left;
      }
    }
    .form-row-first {
      width: 47.0588235294%;
      float: left;
      margin-right: 5.8823529412%;
      clear: both;
    }
    .form-row-last {
      width: 47.0588235294%;
      float: right;
      margin-right: 0;
    }
    .page-template-template-fullwidth-php {
      .form-row-first {
        width: 47.8260869565%;
        float: left;
        margin-right: 4.347826087%;
      }
      .form-row-last {
        width: 47.8260869565%;
        float: right;
        margin-right: 0;
      }
    }
    .storefront-full-width-content .woocommerce-tabs {
      ul.tabs {
        width: 30.4347826087%;
        float: left;
        margin-right: 4.347826087%;
      }
      .panel {
        width: 65.2173913043%;
        float: right;
        margin-right: 0;
        margin-bottom: 3.706325903em;
      }
    }
    .woocommerce-tabs {
      padding-top: 2.617924em;
      padding-bottom: 2.617924em;
      ul.tabs {
        width: 29.4117647059%;
        float: left;
        margin-right: 5.8823529412%;
        li.active:after {
          right: 1em;
        }
      }
      .panel {
        width: 64.7058823529%;
        float: right;
        margin-right: 0;
        margin-top: 0;
      }
    }
  }
  
  @media (min-width: 568px) and (max-width: 66.49894em) {
    .woocommerce-breadcrumb {
      margin-left: -2.618rem;
      margin-right: -2.618rem;
      padding-left: 2.618rem;
      padding-right: 2.618rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 900px) {
    body:not(.page-template-template-fullwidth-php) table.cart {
      td, th {
        padding: 1em;
      }
    }
  }