.custom-popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -99999;
    visibility: hidden;
    opacity: 0;
    background-color: rgba($black, .5);
    transition: opacity .25s ease-in-out;

    &__inner {
        height: auto;
        background-color: $white;
        position: absolute;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        padding: 2.5rem;
        min-width: 90%;
        max-width: 90%;

        @include media-breakpoint-up(md) {
            min-width: 50%;
            max-width: 80%;
        }

        .custom-popup-close {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &.active {
        z-index: 999;
        visibility: visible;
        opacity: 1;
    }
}