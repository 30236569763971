.case-studies-block {
    margin-bottom: 5rem;
    &__image {
       height: 224px;
       img {
            width: 100%;
            height: 100%;
            object-fit: cover;
       }
    }
    &__tags {
        margin: 2rem 0;
        .tag {
            background-color: $white;
            box-shadow: 0 0 4px 0 rgba(8,77,109,0.16);
            color: map-get($theme-colors, secondary);
            padding: .5rem 1rem;
            margin: .5rem;
            display: inline-block;
            font-weight: $font-weight-bold;
        }
    }
    &__content {
        .details {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}