.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.size-contain {
    background-size: contain;
  }
}

.no-decoration {
  &:hover {
    text-decoration: none;
  }
}

.double-column {
  column-count: 2;
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.cover-link {
  @include position(absolute, 0, 0, 0, 0);
}

.pos-rel {
  position: relative;
}

.dark-background {
  background-color: map-get($theme-colors, secondary);
}

.main-background {
  background-color: map-get($theme-colors, primary );
}

.secondary-background {
  background-color: map-get($theme-colors, secondary);
}

.main-color {
  color: map-get($theme-colors, main);
}

.secondary-color {
  color: map-get($theme-colors, secondary);
}