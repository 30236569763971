.feature-block {

    @include media-breakpoint-up(lg) {
        padding: 44px 0 12px;
    }

    // .slider-controls {
    //     position: absolute;
    //     bottom:37px;
    //     left: 43px;
    // }

    &--component {

        &__slider {
            position:relative;
            top: 50%;
            transform: translateY(-50%);
            
            .slick-dots {
                z-index: 10;
                display: flex;
                justify-content: center;
                align-content: center;
                margin: 1rem 0;
                padding: 0;
                li {
                    margin: 0 0.5rem;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    button {
                        font-size: 0;
                        height: 10px;
                        width: 10px;
                        padding: 0;
                        border-radius: 5px;
                        background-color: $transparent;
                        border: 2px solid map-get($theme-colors, primary );
                        outline: none;
                        &:hover {
                            opacity: 0.8;
                            cursor: pointer;
                        }
                    }
        
                    &.slick-active {
                        button {
                            background-color: map-get($theme-colors, primary );
                            border: 2px solid map-get($theme-colors, primary );
                        }
                    }
                }
            }
        }

        &__image {
            margin-top: 28.5px;
            margin-bottom:  28.5px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
        }
        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            &--lede {
                color: map-get($theme-colors, primary);	
                font-size: 16px;
                font-weight: bold;
            	line-height: 24px;
            }

            &--title {
                color: map-get($theme-colors, secondary);	
                font-size: 33px;	
                font-weight: bold;
                line-height: 48px;
                margin-bottom: 15px;
            }

            &--content {
                color: map-get($theme-colors, primary);		
            }
        }

        &__links {
            
            a.btn {
                margin: .25rem;
            }
        }

        &__grid_items {

            &--wrapper {

                margin-top: 0.75rem;

                .icon-grid-item {

                    &.horizontal {

                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        
                        // @include media-breakpoint-up(lg) {
                        //     justify-content: center;
                        // }

                        .icon-grid {
                            
                            &--icon {
                                max-width: 39px; 
                                min-width: 39px;
                                display: inline-block
                            }
                            
                            &--horizontal {
                                padding-left: 27px;
                                display: inline-block;
                            }
                            
                            &--heading {
                                color: map-get($theme-colors, primary);
                                font-size: 1rem;	
                                font-weight: bold;	
                                line-height: 24px;
                                margin-bottom: 15px;
                                margin-top: 0;
                            }
                            &--content {
                                color: map-get($theme-colors, primary);
                                font-size: 14px;	
                                line-height: 22px;
                                max-width: 371px
                                
                            }
                        }
                    }

                    .icon-grid {
                        &--content {
                            p {
                                margin-bottom: 2.25rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .large-image {
        padding-bottom: 102%;
        @include media-breakpoint-up(lg) {
            padding-bottom: 112%;
        }
    }
}