.btn {
  color: map-get(map-get($button-colors, standard), base-color);
  background-color: map-get(
      map-get($button-colors, standard),
      base-background);
  padding: .8125rem;
  
  @include media-breakpoint-up(sm) {
    padding: .8125rem 2.25rem;
  }

  &:hover {
    color: map-get(map-get($button-colors, standard), hover-color);
    background-color: map-get(
      map-get($button-colors, standard),
      hover-background
    );
  }

  @each $btn in $button-colors {
    $key: nth($btn, 1);

    &.btn-#{$key} {
      color: map-get(map-get($button-colors, $key), base-color);
      background-color: map-get(map-get($button-colors, $key), base-background);
      box-shadow: map-get(map-get($button-colors, $key), base-shadow );
      cursor: pointer;


      @include active-state() {
        color: map-get(map-get($button-colors, $key), hover-color);
        background-color: map-get(map-get($button-colors, $key), hover-background);
      }
    }
  }

  &-invert {
    box-shadow: 0 0 6px 0 rgba(8,77,109,0.18);
  }
}

.btn-small {
  padding: 6px 20px;
}

.simple-link-btn {
  font-weight: $font-weight-bold;
  &--primary {
    color: map-get($theme-colors, primary);
  }
  &--secondary {
    color: map-get($theme-colors, secondary);
  }

  &:hover {
    text-decoration: none;
    &:after {
      margin-left: .5rem;
    }
  }

  &--no-icon {
    &:after {
      display: none !important;
    }
  }


  &:after {
    display: inline-block;
    content: '\f061';
    transition: all .25s ease-in-out;
    font-family: "Font Awesome 5 Pro";
    margin: 0 .25rem;
  }
}