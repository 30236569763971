.icon-grid-item {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;

    .icon-grid {
        &--content {
            ul {
                li {
                    margin-bottom: .75rem;
                }
            }
        }
    }

    &.large-icons {
        .icon-grid {
            &--icon {
                width: 150px;
                max-width: 150px;
            }
        }
    }

    .icon-grid {
        &--icon {
            width: 50px;
            max-width: 50px;
            display: inline-block;
        }
        &--horizontal {
            display: inline-block;
            padding-left: 27px;
        }
    }

    &.horizontal {}

    &.vertical {
        flex-direction: column;

        .icon-grid {
            &--icon {
                width: 50px;
                max-width:  50px;
            }
        }
    }

    .feature-block {
        width: 100%;
        height: 100%;
        background-color: map-get($theme-colors, secondary);
        padding: 1rem;
        border-radius: 6px;

        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }
    }
    
}

.pb-icon-grid {
    padding: 44px 0 0;
}