/**
 * Footer base styles
 * @see footer.twig
 */

footer {
    margin-top: 3rem;//5rem;
    padding: 3rem 0 2rem 0;
    &.has-cta {
        padding-top: 5rem; //15rem;
        @include media-breakpoint-up(lg) {
            padding-top: 15rem;
            margin-top: 10rem;
        }
    }

    .social-media {
        &.primary {
            display: flex;
            
            @include media-breakpoint-up(lg) {
                display: none;
            }

            .social-media__link {
                color: map-get($theme-colors, secondary);
                font-size: 33px;

                @include media-breakpoint-up(lg) {
                    @include font-size(26);
                }

                
            }
        }
    }

    &.footer-cta {
        z-index: 1;
        position: relative;
        bottom: -5rem; // -10rem;
        margin-top: -4rem;
        width: 95%;
        margin: 0 auto;
        margin-bottom: -2.5rem;//-7.5rem;
        box-shadow: 0 2px 14px 0 rgba(8,77,109,0.17);
        background-color: $white;
        padding: 47px 2rem;

        @include media-breakpoint-up(lg) {
            width: 100%;
            bottom: -10rem;
            margin-bottom: -7.5rem;
            margin-top: -8rem;
        }

        p {
            color: map-get($theme-colors, primary);
            @include font-size(18);
        }

        .button-wrapper {
            text-align: center;
            margin-top: 20px;
            a.btn {
                margin: 27px 2rem;

                .icon {
                    margin: 0 .5rem;
                }
            }
        }

    }

    .footer {
        p {
            margin-bottom: 0;
            @include font-size(14);
        }

        &__top {}
        &__bottom {
            margin-top: 2rem;
            .newsletter-caption {
                margin: 1rem;

                @include media-breakpoint-up(lg) {
                    margin: 0;
                    margin-right: 2.5rem;
                }
            }
            .copyright-text {
                text-align: center;

                @include media-breakpoint-up(lg) {
                    text-align: right;
                }
            }
            .developed-by {
                text-align: center;
                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }
        }

        &__subheading {
            display: flex;
            justify-content: space-between;
            @include font-size(23);
            font-weight: $font-weight-bold;
            color: map-get($theme-colors, secondary);
            text-transform: uppercase;
            margin-bottom: 0;
            padding: 2rem 0;
            border-bottom: 1px solid rgba(map-get($theme-colors, secondary), .15);

            a.footer-accordion-trigger {
                color: $white;
                display: inline-block;
                transition: all .25s ease-in-out;
                &.accordion-active {
                    transform: rotate(180deg);
                }
                &:hover {
                    text-decoration: none !important;
                }
            }
            
            @include media-breakpoint-up(lg) {
                @include font-size(12);
                margin-bottom: 0;
                padding: 0;
                border-bottom: 0;

                a.footer-accordion-trigger {
                    display: none;
                }
            }
        }

        &__menu {
            display: none;
            padding: 2rem 0 0;

            @include media-breakpoint-up(lg) {
                display: block;
            }

            &.content-show {
                display: block;
            }

            .nav-footer {
                &-ul {
                    display: flex;
                    li {
                        a {
                            font-weight: $font-weight-bold;
                        }

                        .icon-wrapper {
                            display: inline-flex;
                            flex-direction: column;

                            @include media-breakpoint-up(lg) {
                                flex-direction: row;
                            }

                            .icon {
                                margin: 1rem 0;

                                @include media-breakpoint-up(lg) {
                                    margin: 0;
                                    margin-right: .75rem;
                                }

                            }
                            .content {
                                a {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: .75rem;
                                    padding-right: 2rem;

                                    .icon {
                                        color: map-get($theme-colors, secondary);
                                    }
                                }
                                p {
                                    @include font-size(14);
                                    color: $white;
                                    margin-right: 2rem;
                                }
                            }
                        }

                        width: 100%;
                        a {
                            color: $white;
                        }
                    }
                }
            }

            ul.social-media {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: inline-flex;

                }
            }

            &--our-products {
                .nav-footer {
                    &-ul {
                        flex-wrap: wrap;
                        li {
                            width: 100%;
                            display: inline-flex;

                            @include media-breakpoint-up(lg) {
                                width: 50%;
                            }
                        }
                    }
                }
            }
            &--quick-links {
                .nav-footer {
                    &-ul {
                        display: inline-flex;
                        flex-wrap: wrap;
                        li {
                            width: 100%;

                            @include media-breakpoint-up(lg) {
                                width: 50%;
                            }
                        }
                    }
                }
            }
            &--connect {
                .nav-footer {
                    &-ul {
                        flex-direction: column;
                    }
                }
            }
        }
        &__link {
            color: map-get($theme-colors, secondary);
            p {
                margin-bottom: 0;
                display: inline;
                color: map-get($theme-colors, secondary);  
            }
            a {
                color: map-get($theme-colors, secondary);  
            }
        }
        &__link-spacer {
            color: map-get($theme-colors, secondary);
        }
    }
}