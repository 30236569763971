.page-builder--partner-grid {
    .partner-grid-item-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .partner-grid-item {
        padding: 3rem;
        width: 100%;
        position: relative;
        @include media-breakpoint-up(md) {
            width: 33.33%;
        }
        &:nth-child(3n) {
            &:before {
                display: none;
            }
        }

        &:before {
            position: absolute;
            right: 0;
            top: 0;
            display: none;
            content: '';
            width: 2px;
            height: calc(100% - 2rem);
            margin: 1rem 0;
            background-image: url('../images/straight-line.svg');
            background-repeat: no-repeat;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            content: '';
            width: calc(100% - 2rem);
            height: 2px;
            margin: 0 1rem;
            background-image: url('../images/menu-line-horizontal.svg');
            background-repeat: no-repeat;
        }


        &__logo {
            margin-bottom: 1.5rem;

            img {
                display: inline-block;
                width: 125px;
                height: auto;
                min-height: 100px;
            }
        }
    }
}
