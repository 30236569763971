// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/*
 *  Gravity Forms Example Form Styles
 */

// #gform_wrapper_1 {
//   form#gform_1 {
//     .gform_body {
//       // Form List Itmes
//       li.gfield {
//       }
//       // Form List Item Labels
//       label.gfield_label {
//       }
//     }

//     .gform_footer {
//       // Submit Button
//       .button {
//       }
//     }
//   }
// }

.gform_footer {
  button {
    cursor: pointer;
  }
}

.gform_wrapper li.hidden_label textarea {
  margin-top: 12px;
}


// Contact Page - form styling
body.contact {
  .featherlight-popup {
    .contact-page-form {
      .gform_wrapper {
        ul.gform_fields {
          li {
            label {
              font-weight: normal;
              color: map-get($theme-colors, main);
            }
            input, textarea {
              border: 1px solid map-get($theme-colors, secondary);
            }

            &.two-col {
              @include media-breakpoint-up(lg) {
                display: inline-flex;
                flex-direction: column;
                width: 50%;
              }
            }
          }
        }

        .gform_footer {
          input[type=submit] {
            width: 100%;
            background-color: map-get($theme-colors, secondary);
            color: $white;
            height: 40px;
            font-weight: $btn-font-weight;
          }
        }
      }
    }
  }
}
// Contact Page - form styling -- end

.enquiry-form {
  .gform_wrapper {

    .gfield {

      .ginput_container_text,
      .ginput_container_phone,
      .ginput_container_email {
        margin-top: 0;
      }
      label {
        margin-bottom: 0;
        font-weight: normal;
      }
    }
    input[type=text] {
      border: 1px solid rgba(0,0,0,0.1);	
      border-radius: 2px;
      padding: 11px !important;
    }

    .gf-half {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 50%;
        display: inline-block;
      }
    }

    .gf-half-flex {
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1rem;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      .gfield_label {
        @extend .btn;
        @extend .btn-badge;

        position: relative;

        &:before {
          content: "\f019";
          font-family: "Font Awesome 5 Pro";
          font-size: 14px;
          color: map-get($theme-colors, secondary);
          margin-right: 10px;
        }

        &:hover {
          &:before {
            color: #fff;
          }
        }
      }

      input[type=file] {
        width: 100%;
        color: rgba(8,77,109,0.33);
        &::-webkit-file-upload-button {
          opacity: 0;
          width: 0;
          padding: 0;
        }
      }
    }
  }

  .gform_footer {
    text-align: center;

    input[type=submit] {
      @extend .btn;
      @extend .btn-secondary;
      margin-right: 0 !important;
      display: inline-block;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}

.newsletter-form_wrapper {
  width: 100% !important;
  @include media-breakpoint-up(lg) {
    margin: 0 !important;
  }

  
}
.newsletter-form {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
  .gform_body {
    width: 100% !important;
    @include media-breakpoint-up(lg) {
      width: 75% !important;
    }
    .gform_fields {
      .gfield {
        display: inline-block;
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 50%;
        }

        input[type=text], input[type=email] {
          background-color: $transparent;
          border: 0;
          padding: .5rem .75rem !important;
          border-bottom: 2px solid map-get($theme-colors, secondary);
          color: $white;
          &:focus {
            outline: 0;
          }
          &::placeholder {
            color: rgba($white, .6);
          }
        }
      }
    }
  }
  .gform_footer {
    width: 100% !important;
    @include media-breakpoint-up(lg) {
      width: 25% !important;
    }
    input[type=submit] {
      @extend .btn;
      @extend .btn-secondary;
      margin-right: 0 !important;
      display: inline-block;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

}

// Page builder Block - Contact Form Block
.page-builder-blocks--contactform {
  .gform_wrapper {
    width: 100%;
  }


  form {
    display: flex;
    flex-direction: column;
    .gform_body {
      .gform_fields {
        .gfield {
          width: 100%;
        }
      }
    }

    input[type=text], input[type=email], textarea {
      border: 0;
      border-bottom: 2px solid map-get($theme-colors, primary);
      width: 100%;
      color: map-get($theme-colors, secondary) !important;
      &::placeholder {
        color: map-get($theme-colors, primary) !important;
      }
    }

    input[type=submit] {
      @extend .btn;
      @extend .btn-secondary;
      margin-right: 0 !important;
      display: inline-block;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}