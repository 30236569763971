/////////////////////////////////////////////
//** Variables **//
//** This is the file for basic overrides of bootstraps variables. **//
//** You can accomplish a lot of the site setup here. If you need access to more, checkout bootstrap_components/bootstrap/ and find the variables file. **//
/////////////////////////////////////////////


// This can be removed, purely for starter theme styling
$sc-gradient: linear-gradient(90deg, #43E695 0%, #3BB2B8 100%);

// Border Radius Settings
//
// This defines the border radius globally across elements including buttons.
$border-radius:          0rem !default;
$border-radius-lg:       .3rem !default;
$border-radius-sm:       .2rem !default;

/*
 * Color System
 */
$white:    #fff;
$gray:     #373737;
$gray-alt: #F3F3F3;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
$transparent: rgba(0,0,0,0);

$grays: (
        100: $gray-100,
        200: $gray-200,
        300: $gray-300,
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900
) !default;

// Colors
//
// Regular Colors
$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #F26522;
$yellow:  #ffc107;
$green:   #287a7e;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
        blue:      $blue,
        indigo:    $indigo,
        purple:    $purple,
        pink:      $pink,
        red:       $red,
        orange:    $orange,
        yellow:    $yellow,
        green:     $green,
        teal:      $teal,
        cyan:      $cyan,
        white:     $white,
        gray:      $gray,
        gray-dark: $gray-800
) !default;

// Colors
//
// Theme Colors
$theme-colors: (
        primary: #084D6D,
        secondary: #5BC4BF,
        tertiary: $gray-alt,
        company-grey: #E3EBED,
        success: $orange,
        info: $gray,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800,
        in-stock: $green,
        out-of-stock: $red
) !default;

// Colors
//
// Button Colors
//
// @see _buttons.scss
$button-colors: (
        // Standard (.btn)
        standard: (
                base-background: map-get($theme-colors, primary),
                base-color: $white,
                hover-background: darken(map-get($theme-colors, primary), 15%),
                hover-color: $white,
        ),
        // Primary Button (.btn .btn-primary)
        primary: (
                base-background: map-get($theme-colors, primary),
                base-color: $white,
                hover-background: darken(map-get($theme-colors, primary), 15%),
                hover-color: $white
        ),
        // Secondary Buttons (.btn .btn-secondary)
        secondary: (
                base-background: map-get($theme-colors, secondary),
                base-color: $white,
                hover-background: darken(map-get($theme-colors, secondary), 15%),
                hover-color: $white
        ),
        // White BG and aqua text
        invert: (
                base-background: $white,
                base-color: map-get($theme-colors, secondary),
                hover-background: darken(map-get($theme-colors, secondary), 15%),
                hover-color: $white,
        ),
        ghost: (
                base-background: transparent,
                base-color: map-get($theme-colors, primary),
                hover-background: map-get($theme-colors, primary),
                hover-color: $white,

        ),
        badge: ( 
                base-background: transparent,
                base-color: map-get($theme-colors, secondary),
                hover-background: map-get($theme-colors, primary),
                hover-color: $white,
                base-shadow: 0 0 4px 0 rgba(8,77,109,0.16)
        )
);

// Button Settings
//
// Padding
//
// For each of Bootstrap's buttons, define text, background and border color.

// Standard Buttons 13x36
$input-btn-padding-y:       0.8125rem; // 13px
$input-btn-padding-x:       2.25rem; // 40px
$input-btn-line-height:     1; // 1 line height

// Small Buttons
$input-btn-padding-y-sm:    .25rem;
$input-btn-padding-x-sm:    .5rem;
$input-btn-line-height-sm:  1.5;

// Large Buttons
$input-btn-padding-y-lg:    .75rem; // 12px
$input-btn-padding-x-lg:    2.5rem; // 40px
$input-btn-line-height-lg:  1.5;

$btn-block-spacing-y:            .5rem !default;
$btn-font-weight:                bold;

// Button Settings
//
// Border Radius
//
// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;

$btn-transition:                 all .15s ease-in-out;


// Grid containers
//
// Container max widths

$container-max-widths: (
       sm: 540px,
       md: 720px,
       lg: 960px,
       xl: 1140px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff;
$body-color: #000;

// Links
//
// Style anchor elements.
$link-color: map-get($theme-colors, primary);
$navigation-link-color: map-get($theme-colors, primary);
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;

/*
 * Fonts
 *
 * Font, line-height, and color for body text, headings, and more.
 */

// Font Families
$font-family-open-sans: 'Montserrat', sans-serif;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-roboto: 'Roboto', $font-family-open-sans;
$font-family-base:       $font-family-open-sans;

// Font Sizes
$font-size-base: 0.875rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1.125rem; // 18px 
$font-size-sm:   .875rem; // 14px

// $h1-large:       4rem;

// Headings
$headings-font-family:   $font-family-open-sans;
$headings-font-weight:   700;
$headings-color:         map-get($theme-colors, primary);
$headings-margin-bottom: 13px;

// Font Weights
$font-weight-normal: normal;
$font-weight-bold:   bold;
$font-weight-base:   $font-weight-normal;
$line-height-base:   1.57;
$headings-line-height:   1.2;

/**
 * Font Sizes
 */
 $h1-font-size: 3.5625rem; // 57px
 $h2-font-size: 2.9375rem; // 47px
 $h3-font-size: 2.0625rem;  // 33px
 $h4-font-size: 1.75rem; // 28px
 $h5-font-size: 1.25rem; // 24px
 $h6-font-size: 1rem; // 20px

 $spacer: 1.25rem;

/**
 * Page Builder Settings
 */
$page-builder-block-spacing: 30px;

/**
 *  Menu Animations
 */
 $menu-animation-timing: 0.5s ease-in-out;
 $mobile-menu-width: 300px;

 $primary-nav-padding: 15px;
