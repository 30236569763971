/* ==========================================================================
    General Navigation Styles
   ========================================================================== */
.nav {
  &-list {
    &__item {
    }
    &__link {
      color: $navigation-link-color;
      span.icon {
        color: map-get($theme-colors, secondary );
        margin-left: 0.75rem;
      }
    }

    &--dropdown & {
      &__item {
      }
      &--link {
      }
    }
  }

  /* Primary Navigation
   ========================================================================== */
  &--primary {
    .nav {
      &-list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 0;
        margin: 0;

        &__item {
          list-style-type: none;
          margin: 0 7.5px;
          position: relative;

          /**
           * Move dropdown menu to right of last menu to account for page-overflow,
           * could probably alter this to nth-child(x+) for of the list is actually
           * greather than nth amount of items.
           */

          &:last-of-type {
            .nav-drop {
              right: 0;
              left: initial;
            }
          }

          @include active-state() {
            & > .nav-list--dropdown {
              // transform: translateY(100%);
              opacity: 1;
              pointer-events: all;
            }
          }
        }

        &__link {
          @include font-size(16);
          color: #084D6D;	
          font-weight: bold;	
          line-height: 24px;
          padding: 5px 7.5px;
         
          @include media-breakpoint-up(md) {
            @include font-size(14);
          }

          &.btn-link {
            color: $white !important;
            text-decoration: none;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-right:  0;
          }
        }

        

        &--dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          padding: 30px 0 0 0;
          z-index: 10;
          width: 264px;
          opacity: 0;
          pointer-events: none;
          transition: all 0.25s ease-in-out;

          &.megamenu {
            width: 750px;
            left: -25%;
            transform: translateX(0);
            // box-shadow: 0 10px 5px 0 rgba(8,77,109,0.18);

            .item-heading {
              background-color: #E3EBED;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 23px;
              position: relative;

              &:before {
                position: absolute;
                top: -1.25rem;
                left: 2.5rem;
                content: '';
                display: block;
                width: 0; 
                height: 0; 
                border-left: 22px solid $transparent;
                border-right: 22px solid $transparent;
                border-bottom: 24.5px solid #E3EBED;;
              }

              .item-title {
                color: map-get($theme-colors, primary);
                font-size: 25px;	
                font-weight: bold;	
                line-height: 34px;
              }
            }

            .dropdown-container {
              background-color: $white;
              padding: 30px;
              
              .dropdown-links {
                display: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 0;

                &__link {
                  display: flex;
                  position: relative;
                  width: 50%;
                  min-height: 150px;
                  &:before {
                    position: absolute;
                    right: 0;
                    top: 0;
                    
                  }
                  &:nth-child(2n) {
                    &:before {
                      display: none;
                    }
                  }
                  
                  &:before {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: block;
                    content: '';
                    width: 2px;
                    height: calc(100% - 2rem);
                    margin: 1rem 0;
                    background-image: url('../images/straight-line.svg');
                    background-repeat: no-repeat;
                  }
                  &:after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    content: '';
                    width: calc(100% - 2rem);
                    height: 2px;
                    margin: 0 1rem;
                    background-image: url('../images/menu-line-horizontal.svg');
                    background-repeat: no-repeat;
                  }

                  .item-link {
                    display: flex;
                    flex-direction: row;
                    padding: 1rem;

                    &:hover {
                      text-decoration: none;
                      
                      .link-text {
                        color: map-get($theme-colors, secondary);
                        .icon {
                          margin-left: .5rem
                        }
                      }
                    }
                    .menu-icon {
                      display: block;
                      width: 35px;
                      height: 35px;
                      min-width: 35px;
                      margin-right: 1rem;
                    }
                    .link-text {
                      font-weight: $font-weight-bold;
                      margin-bottom: .5rem;
                      .icon {
                        transition: all .25s ease-in-out;
                        margin-left: .25rem;
                      }
                    }
                    .menu-blurb {
                      font-weight: $font-weight-normal;
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }

          }

          &:not(.megamenu) {

            
            .dropdown-container {
              background-color: #fff;
              position: relative;
              box-shadow: 0 0 5px 0 rgba(8,77,109,0.18);
              
              &:before {
                content: "";
                width: 30px;
                height: 30px;
                background-color: #fff;
                box-shadow: 0 0 5px 0 rgba(8,77,109,0.18);
                position: absolute;
                top: -7px;
                left: 15px;
                transform: rotate(45deg);
                z-index: -1;
              }
              &:after {
                content: "";
                width: 30px;
                height: 30px;
                background-color: #fff;
                // box-shadow: 0 0 5px 0 rgba(8,77,109,0.18);
                position: absolute;
                top: -7px;
                left: 15px;
                transform: rotate(45deg);
              }
              
            }
            
            .nav-list {
              &__item { 
                // padding: 10px 15px;
                margin: 0 20px;
                z-index: 999;
                
                &:last-of-type {
                  
                  .nav-list__link {
                    border-bottom: none;
                  }
                }
              }
              
              &__link {
                text-align: center;
                display: block;
                padding: 15px 30px;
                line-height: 24px;
                border-bottom: 1px solid #E3EBED;
                
                
              }
            }
          }
        }
      }
    }
  }

  /* Primary Sub Navigation
  ========================================================================== */
  &--sub-primary {
    .nav-list {
      list-style-type: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;

      &__item {
        margin: 0 7.5px;
      }

      &__link {
        @include font-size(12);
        padding: 0px 4px;
        color: rgba(8,77,109,0.33);	 
        font-weight: bold;	
        letter-spacing: 0.75px;	
        line-height: 20px;
        text-transform: uppercase;
      }
    }
  }

  /* Mobile Navigation
   ========================================================================== */
  &--mobile {
    @include position(fixed, 0, null, null, 0);
    @include transform(translate3d(-50%, -500%, 0));
    top: -50px;
    background-color: $white;
    left: 50%;
    height: calc(100% - 5%); //100vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    z-index: 9999;
    transition: transform $menu-animation-timing;

    > .nav-list__level {
      display: inline-flex;
      height: 80%;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      
      ul.nav-list {
        margin-bottom: 102px;
      }

      .nav-cta {
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0 !important;
      }
    }

    

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
    }

    .nav-list {
      display: flex;
      flex-wrap: wrap;
      &__level {
        width: 100%;

        .nav-list {
          &__item {
            @include font-size(18);
            width: 100%;            
            &.feature-link {
              display: inline-flex;
              width: 100%;
              font-weight: $font-weight-bold;
            }
            &.no-link {
              width: 100%;
              &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                border-top: 1px solid rgba(map-get($theme-colors, "primary"), 0.2);
                margin: 1.5rem 0;
              }
              > a.menu-item {
                color: rgba(map-get($theme-colors, "primary"), 0.33);
                font-weight: $font-weight-bold;
                @include font-size(12);
                margin-bottom: 1.5rem;
                text-transform: uppercase;
                display: block;
                margin-bottom: 1rem;
              }
            }
            &.menu-item-has-children {
              width: 100%;
              display: flex;
              flex-wrap: wrap;

              .nav-list__level {
                ul.nav-list {
                  li.nav-list__item {
                    width: 50%;
                  }
                }
              }
            }
          }
          &__link {
            font-size: 1rem;
            width: 100%;
          }
        }

        .nav-cta {
          background-color: map-get($theme-colors, "company-grey");
          margin: 15px -30px -30px -30px;
          padding: 30px 0;
          
          
          ul.nav-cta__links {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            li {
              .btn-link {
                font-weight: $font-weight-bold;
                color: map-get($theme-colors, "primary");
              }
            }
          }
        }

      }
    }

    &-list {
      & > .nav-list {
        &__level {
          
          box-shadow: none;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }

      &__item {
      }
      &__link {
      }

      &__level {
        @include position(absolute, 0, null, null, 0);
        @include transform(translate3d(-100%, 0, 0));

        @include pseudo(after, "") {
          @include position(absolute, 0, 0, null, null);
          opacity: 0;
        }

        height: 100%;
        width: 100%;
      }

      &--dropdown & {
        &__item {
        }
        &--link {
        }
      }
    }
  }
}

/* ==========================================================================
   Mobile Navigation Setup // Styles
   ========================================================================== */

html,
body,
.main,
.site--wrapper {
  height: 100%;
}

.site--wrapper {
  position: relative;

  &::after {
    content: '';
    @include position(absolute, 0, 0, 0, 0);
    top: -120px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
    opacity: 0;

    transition: opacity $menu-animation-timing;
    pointer-events: none;
  }
}

.mobile-navigation { 
  &__wrapper {
    position: relative;
  }

  &__push {
    position: relative;
  }

  &__controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
  }

  &__exit {
    @include font-size(30);

    min-width: 30px;
    padding: 1rem 2rem;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    color: map-get($theme-colors, "primary");
    z-index: 99;
    pointer-events: all;
  }

  &__logo {
    position: relative;

    img {
      width: 120px;
    }
  }
}

.main {
  position: relative;
  overflow: hidden;

  &.menu-active {
    .nav--mobile {
      @include transform(translate3d(-50%, 10%, 0));
      left: 50%;
    }

    .site--wrapper::after {
      opacity: 1;
    }
  }
}


// Product Menu
.site-header {
  &.hide-on-load {
    display: none;
  }
}

.nav-product {
  display: none;
  position: relative;
  padding: 40px 0;
  background-color: $white;
  box-shadow: 0 2px 14px 0 rgba(8,77,109,0.09);
  z-index: 999;
  transition: all 0.2s;

  &.fixed-nav {
    top: 0;
    position: fixed;
    width: 100%;
    transition: all 0.2s;
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &__links {
    ul.standard-links {
      display: inline-flex;
      list-style: none;
      margin: 0;
      padding: 0;
  
      li {
        margin-right: 41.5px;
        a {
          @include font-size(16);
          font-weight: $font-weight-bold;
          transition: all .25s ease-in-out;
          &:hover {
            text-decoration: none;
            color: map-get($theme-colors, secondary);
          }
        }
      }
    }
  }
  &__buttons {
    .btn {
      margin-right: 20px;
      padding: .8125rem 1rem;


      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.fancy-select {
  > .dropdown-toggle {
    background-color: $transparent;
    border: 0;
    color: map-get($theme-colors, primary);
    @include font-size(16);
    font-weight: $font-weight-bold;
    padding: 0;
    margin: 0;
    width: auto;

    .filter-option {
      overflow: inherit;
      width: auto;

      .filter-option-inner-inner {
        color: map-get($theme-colors, secondary);
        line-height: 1.5rem;
      }
    }

    &:after {
      border-top: .3em solid map-get($theme-colors, secondary);
    }
    
  }
  .dropdown-item {
    li {
      a {
        span.icon {
          display: block;
          color: map-get($theme-colors, secondary);
          margin-right: .75rem;
        }
      }
    }
  }

.dropdown-menu {
  top: 1rem;

  li {
    a {
      padding-top: 20px;
      padding-bottom: 20px;
      span.text {
        @include font-size(16);
        font-weight: $font-weight-bold;
      }
    }
  }
}
.dropdown-item {
  color: map-get($theme-colors, primary);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: $transparent;
  color: map-get($theme-colors, secondary);
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: map-get($theme-colors, secondary);
  background-color: $transparent;
}

.show > .btn-light.dropdown-toggle {
  background-color: $transparent;
  color: map-get($theme-colors, secondary);
}

.dropdown-menu.show {
  top: 2rem !important;
}

&:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: auto !important;
}

// Dropdown Icons
button.dropdown-toggle:hover {
  cursor: pointer;
  color: map-get($theme-colors, primary) !important;
  transition: all .25s ease-in-out;
}
button.dropdown-toggle:hover .filter-option-inner-inner {
  color: map-get($theme-colors, primary) !important;
}

.dropdown-menu li a {
  display: flex;
  align-items: center;
  &:before {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: .75rem;
    content: '';
    background-size: contain;
  }

  &#bs-select-1-0 {
    &:before {
      background-image: url("../images/icons/learning.svg");
    }
  }
  &#bs-select-1-1 {
    &:before {

      background-image: url("../images/icons/employee.svg");
    }
  }
  &#bs-select-1-2 {
    &:before {
      background-image: url("../images/icons/engagement.svg");
    }
  }
  &#bs-select-1-3 {
    &:before {
      background-image: url("../images/icons/selection.svg");
    }
  }
  &#bs-select-1-4 {
    &:before {
      background-image: url("../images/icons/engagement.svg");
    }
  }
}

}
